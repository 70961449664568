import React from 'react';
import { PendingPropertyIndicator, PropertyIndicatorContainer } from './utils';
import { CaretIncrease } from '../../../icons/caret-increase';
import { CaretDecrease } from '../../../icons/caret-decrease';
import { Crosshairs } from '../../../icons/crosshairs';
import { PlatformBuyIcon } from '../../nav-icons';
import { formatMetricPercentage } from '../../hero-metrics';

type Inflection = {
  direction: 'increase' | 'decrease';
  sentiment: 'positive' | 'negative';
};

type Props = {
  difference: number;
  label: string;
  inflection?: Inflection | 'neutral';
};

export function CampaignPercentDifferenceIndicator({ difference, inflection }: Props) {
  if (inflection === undefined) {
    return (
      <PendingPropertyIndicator
        label={
          <div className="flex items-center gap-2">
            Pending
            <PlatformBuyIcon />
          </div>
        }
      />
    );
  }

  if (inflection === 'neutral') {
    return (
      <PropertyIndicatorContainer className="bg-gray-100">
        <div className="flex items-center justify-between text-[13px] font-bold">
          <Crosshairs />
          On-target
        </div>
      </PropertyIndicatorContainer>
    );
  }

  return (
    <PropertyIndicatorContainer className="bg-gray-100">
      <DifferenceLabel difference={difference} inflection={inflection} />
    </PropertyIndicatorContainer>
  );
}

export function LineItemPercentDifferenceIndicator({ difference, inflection, label }: Props) {
  if (inflection === undefined) {
    return (
      <PendingPropertyIndicator
        label={
          <div className="flex items-center gap-2">
            Pending
            <PlatformBuyIcon />
          </div>
        }
      />
    );
  }

  if (inflection === 'neutral') {
    return (
      <PropertyIndicatorContainer className="bg-gray-100">
        <div className="flex items-center justify-between text-[13px] font-bold leading-[20px]">
          <Crosshairs />
          {label}
        </div>
      </PropertyIndicatorContainer>
    );
  }

  return (
    <PropertyIndicatorContainer className="bg-gray-100">
      <DifferenceLabel difference={difference} inflection={inflection} />
    </PropertyIndicatorContainer>
  );
}

function DifferenceLabel({
  difference,
  inflection
}: {
  difference: number;
  inflection: Inflection;
}) {
  const { direction, sentiment } = inflection;
  return (
    <div className="flex items-center justify-between">
      {direction === 'increase' ? (
        <CaretIncrease sentiment={sentiment} />
      ) : (
        <CaretDecrease sentiment={sentiment} />
      )}
      <div className="flex items-center gap-1 text-[13px] leading-[20px]">
        <span className="font-bold">{formatMetricPercentage(difference)}</span>
        {direction === 'increase' ? 'More' : 'Less'}
      </div>
    </div>
  );
}
