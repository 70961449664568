import React from 'react';
import {
  PendingPropertyIndicator,
  ProgressBarContainer,
  PropertyIndicatorLabel,
  PropertyIndicatorContainer,
  calcProportion
} from './utils';
import { PlatformBuyIcon } from '../../nav-icons';
import { formatMetricPercentage } from '../../hero-metrics';

type Props = {
  completed: number;
  total: number;
};
export function PercentCompletedIndicator({ completed, total }: Props) {
  if (total === 0) {
    return (
      <PendingPropertyIndicator
        label={
          <div className="flex items-center gap-2">
            Pending
            <PlatformBuyIcon />
          </div>
        }
      />
    );
  }

  const percentCompleted = calcProportion(completed, total);

  return (
    <PropertyIndicatorContainer className="bg-gray-100">
      <PropertyIndicatorLabel className="text-gray-800">{`${formatMetricPercentage(completed / total)}`}</PropertyIndicatorLabel>
      <ProgressBarContainer>
        {percentCompleted > 100 ? (
          <>
            <div
              className="rounded-l-[3px] bg-gray-500"
              style={{ width: `${100 - ((percentCompleted - 100) / percentCompleted) * 100}%` }}
            />
            <div
              className="progress-bar-over-allocated-shadow rounded-r-[3px] bg-gradient-to-r from-[#FBBF24] to-[#CA8A04]"
              style={{ width: `${((percentCompleted - 100) / percentCompleted) * 100}%` }}
            />
          </>
        ) : (
          <div
            className="h-1 rounded-[3px] bg-gray-500"
            style={{ width: `${percentCompleted}%` }}
          />
        )}
      </ProgressBarContainer>
    </PropertyIndicatorContainer>
  );
}
