import React from 'react';
import { Button } from '../../components/button';
import { useStore } from '../../store/store';
import { v4 } from 'uuid';
import { UTCDate } from '@date-fns/utc';
import { addDays, isBefore, isSameDay } from 'date-fns';
import { useCaptureLineItemCreate } from '../../utils/posthog/analytics';
import { Campaign } from 'shared/src/campaign-types';

type Props = {
  strategyId: string;
  campaign: Campaign;
};

export function NewLineItemButton({ strategyId, campaign }: Props) {
  const addLineItem = useStore(state => state.addLineItem);
  const captureCreateEvent = useCaptureLineItemCreate();

  function createLineItem() {
    const { start_date, end_date } = getDefaultDates(campaign);

    const newLineItemId = v4();
    addLineItem({
      id: newLineItemId,
      description: 'Test',
      strategy_id: strategyId,
      price: campaign.budget || 0,
      pacing_type: 'lifetime',
      pacing_details: null,
      audience: '',
      targeting: '',
      ad_formats: '',
      start_date,
      end_date,
      media_traders: [],
      media_platforms: [],
      is_deleted: false
    });

    captureCreateEvent(newLineItemId, 'new', campaign);
  }

  return (
    <>
      <Button disabled={!campaignHasBudget(campaign)} onClick={createLineItem}>
        <div className="mr-2">New Line Item</div>
      </Button>
    </>
  );
}

function getDefaultDates(campaign: Campaign) {
  const defaultStartDate = new UTCDate();
  const defaultEndDate = addDays(defaultStartDate, 1);
  const start_date = campaign.flight_date ? new UTCDate(campaign.flight_date) : defaultStartDate;
  const end_date = campaign.end_date ? new UTCDate(campaign.end_date) : defaultEndDate;

  // If we get bad data from Zoho (end date earlier than start date), reset to defaults
  return isBefore(end_date, start_date) || isSameDay(end_date, start_date)
    ? { start_date: defaultStartDate, end_date: defaultEndDate }
    : { start_date, end_date };
}

function campaignHasBudget(campaign: Campaign): boolean {
  return campaign.budget != null && campaign.budget > 0;
}
