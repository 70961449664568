import { TeamUser } from 'shared/src/user-types';

export const clientSuccessDirectorRoles = ['Client Success Director'];
export const leadAccountManagerRoles = ['Lead Account Manager'];
export const seniorMediaTraderRoles = ['Senior Media Trader'];
export const adOpsRoles = ['Ad Ops Supervisor', 'Ad Ops Specialist'];
export const analyticsRoles = ['Tag Specialist'];
export const mediaTradersRoles = [
  'Media Trader',
  'Senior Media Trader',
  'Associate Trader',
  'SEM Specialist',
  'Senior SEM Specialist'
];

export function filterMediaTraders(users: TeamUser[] | null) {
  if (!users) return [];
  return users.filter(user => mediaTradersRoles.includes(user.rep_type));
}

export function filterSeniorMediaTraders(users: TeamUser[] | null) {
  if (!users) return [];
  return users.filter(user => seniorMediaTraderRoles.includes(user.rep_type));
}

export function filterClientSuccessDirectors(users: TeamUser[] | null) {
  if (!users) return [];
  return users.filter(user => clientSuccessDirectorRoles.includes(user.rep_type));
}

export function filterAnalysts(users: TeamUser[] | null) {
  if (!users) return [];
  return users.filter(user => analyticsRoles.includes(user.rep_type));
}

export function filterAdOps(users: TeamUser[] | null) {
  if (!users) return [];
  return users.filter(user => adOpsRoles.includes(user.rep_type));
}
