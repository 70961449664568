import React from 'react';
import { Route, Routes, useParams } from 'react-router-dom';
import { PendingChangesButton } from '../pending-changes/pending-changes-button';
import { MediaBuyOverview } from './media-buy-overview-page';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGlobePointer } from '@fortawesome/pro-light-svg-icons';
import { useStrategyContext } from '../line-items/strategy-context';
import { RefreshMediaBuyButton } from './refresh-media-buy-button';
import { NavItem } from '../../components/navitem';
import { TopSection } from '../../components/layout';
import { generateDeeplink } from './platform-buy-utils';
import { MediaBuyBreadcrumbs } from '../shared/breadcrumbs';
import { BEESWAX_MEDIA_PLATFORM_ID } from 'shared/src/media-platforms';
import { PlatformBuy } from 'shared/src/media-buy-types';
import { useCaptureMediaPlanView } from '../../utils/posthog/analytics';

export function MediaPlanPage() {
  const { strategy, refetch } = useStrategyContext();
  const { mediaPlanId, lineItemId } = useParams();
  const lineItem = strategy.line_items.find(li => li.id === lineItemId);
  const mediaPlan = lineItem?.media_plans.find(plan => plan.id === mediaPlanId);
  useCaptureMediaPlanView(strategy.campaign, lineItem, mediaPlan);

  if (!lineItem || !mediaPlan || !mediaPlan.media_buy) {
    console.warn(`Line item with id ${lineItemId} not found`);
    return null;
  }

  const { media_buy } = mediaPlan;

  return (
    <div className="flex w-full flex-col">
      <TopSection>
        <MediaBuyBreadcrumbs
          campaign={strategy.campaign}
          lineItem={lineItem}
          mediaPlan={mediaPlan}
        />
        <div className="flex-1" />
        <div className="flex items-end justify-between">
          <div className="flex items-end">
            <NavItem to="overview" label="Overview" />
            <NavItem to="settings" label="Settings" />
          </div>
          <div className="ml-auto flex space-x-4 pb-2">
            {media_buy.id && media_buy.media_platform_id === BEESWAX_MEDIA_PLATFORM_ID && (
              <RefreshMediaBuyButton mediaBuyId={media_buy.id} refetch={refetch} />
            )}
            <DeepLink mediaBuy={media_buy} />
          </div>
        </div>
      </TopSection>
      <PendingChangesButton />
      <Routes>
        <Route
          path="overview"
          element={<MediaBuyOverview mediaPlan={mediaPlan} lineItem={lineItem} />}
        />
        <Route
          path="settings"
          element={<MediaBuyOverview mediaPlan={mediaPlan} lineItem={lineItem} />}
        />
      </Routes>
    </div>
  );
}

function DeepLink({ mediaBuy }: { mediaBuy: PlatformBuy }) {
  if (hasMediaEntity(mediaBuy)) return null;

  return (
    <a
      target="_blank"
      href={generateDeeplink(mediaBuy)}
      className="text-xs font-light text-blue-600"
      rel="noreferrer">
      <FontAwesomeIcon className="mr-2" icon={faGlobePointer} />
      View on Platform
    </a>
  );
}

// TODO[mk] - MEDIA_PLANS - need to get the media entity details somewhere else
function hasMediaEntity(mediaBuy: PlatformBuy) {
  const { media_platform_entity } = mediaBuy;
  return (
    !media_platform_entity?.raw_entity ||
    Object.keys(media_platform_entity?.raw_entity).length === 0
  );
}
