import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { client } from '../../utils/trpc-client';
import '@ag-grid-community/styles/ag-grid.css';
import '@ag-grid-community/styles/ag-theme-quartz.css';
import {
  ColDef,
  GridApi,
  GridReadyEvent,
  ModuleRegistry,
  SortModelItem,
  StatusPanelDef
} from '@ag-grid-community/core';
import { MenuModule } from '@ag-grid-enterprise/menu';
import { SetFilterModule } from '@ag-grid-enterprise/set-filter';
import { StatusBarModule } from '@ag-grid-enterprise/status-bar';
import { FiltersToolPanelModule } from '@ag-grid-enterprise/filter-tool-panel';
import { ServerSideRowModelModule } from '@ag-grid-enterprise/server-side-row-model';
import { AgGridReact, CustomCellRendererProps } from '@ag-grid-community/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import { PageTitle } from '../shared/page-title';
import { formatDate } from 'shared/src/date-utils';
import { SearchRow } from '../../components/search-bar';
import { showErrorToast } from '../../components/error-toast';
import { defaultColDef } from '../../components/table-utils';
import { AllCampaignsIcon } from '../../components/nav-icons';
import { getAllCampaignUrlParams, storeAllCampaignUrlParams } from '../../utils/url-utils';
import { TotalStatusPanel } from '../shared/totals-status-panel';
import type { RowClickedEvent } from '@ag-grid-community/core/dist/types/src/events';
import { useCaptureListingPageView } from '../../utils/posthog/analytics';
import {
  CampaignFilterTypes,
  CampaignListRequest,
  CampaignListRow,
  CampaignSort,
  CampaignSortColumn,
  campaignSortColumns
} from 'shared/src/campaign-types';
import { autoSizeStrategy } from '../line-items/line-items-table';
import { zohoCampaignStatusValues } from 'shared/src/zoho-types';
import { formatMoney } from 'shared/src/money-utils';

ModuleRegistry.registerModules([
  ServerSideRowModelModule,
  MenuModule,
  SetFilterModule,
  StatusBarModule,
  FiltersToolPanelModule
]);

export function AllCampaigns() {
  const [searchText, setSearchText] = useState<string>('');
  const [gridApi, setGridApi] = useState<GridApi<CampaignListRow> | null>(null);
  const columnDefs: ColDef<CampaignListRow>[] = useMemo(() => createColumnDefs(), []);
  const navigate = useNavigate();
  useCaptureListingPageView('campaign');

  function handleRowClick(clickEvent: RowClickedEvent<CampaignListRow>) {
    const { event, data } = clickEvent;
    if (!data) return;
    const url = `/campaigns/${data.campaign_id}/strategy/performance`;
    if (isMetaKeyPressed(event)) {
      window.open(url, '_blank');
    } else {
      navigate(url);
    }
  }

  useEffect(() => {
    if (gridApi) {
      gridApi.setGridOption('serverSideDatasource', {
        getRows: async params => {
          const parsedFilters = CampaignFilterTypes.safeParse(params.request.filterModel);
          if (!parsedFilters.success) {
            console.error('Failed to parse campaigns filter model', parsedFilters.error);
            return;
          }
          try {
            const sort = getCurrentSort(params.request.sortModel);
            const filters = parsedFilters.data;
            const page = params.request.endRow ? Math.ceil(params.request.endRow / 50) : 1;
            const requestParams = { page, sort, filters, search: searchText };
            const { campaigns, total } = await client.allCampaigns.query(requestParams);
            params.success({ rowData: campaigns, rowCount: total });
            storeAllCampaignUrlParams({ filters, sort });
          } catch (error) {
            console.error('Failed to fetch campaigns', error);
            showErrorToast();
            return;
          }
        }
      });
    }
  }, [gridApi, searchText]);

  function clearFilters() {
    if (gridApi) {
      gridApi.setFilterModel(null);
      gridApi.applyColumnState({ defaultState: { sort: null } });
    }
  }

  const onGridReady = useCallback((params: GridReadyEvent) => {
    setGridApi(params.api);
    const urlParams = getAllCampaignUrlParams();
    if (urlParams) {
      const { filters, sort } = urlParams;
      params.api.setFilterModel(filters);
      if (sort) params.api.applyColumnState({ state: [sort], defaultState: { sort: null } });
    } else {
      params.api.setFilterModel(defaultFilters());
    }
  }, []);

  const statusBar = useMemo<{
    statusPanels: StatusPanelDef[];
  }>(() => {
    return {
      statusPanels: [{ statusPanel: TotalStatusPanel }]
    };
  }, []);

  return (
    <div className="flex h-full w-full flex-col p-12">
      <PageTitle title="Bravo | Campaigns" />
      <SearchRow
        icon={<AllCampaignsIcon />}
        title="All Campaigns"
        placeholder="Search By Campaign"
        submitSearch={setSearchText}
      />
      <div
        className="mb-1 flex h-4 cursor-pointer items-center justify-end text-sm text-blue-400 underline"
        onClick={clearFilters}>
        Clear Filters
      </div>
      <div className="ag-theme-quartz" style={{ height: '100%', width: '100%' }}>
        <AgGridReact
          columnDefs={columnDefs}
          defaultColDef={defaultColDef}
          rowBuffer={0}
          rowModelType={'serverSide'}
          cacheBlockSize={50}
          cacheOverflowSize={2}
          isExternalFilterPresent={() => true}
          infiniteInitialRowCount={100}
          maxBlocksInCache={10}
          sideBar="filters"
          autoSizeStrategy={autoSizeStrategy}
          onGridReady={onGridReady}
          statusBar={statusBar}
          onRowClicked={handleRowClick}
        />
      </div>
    </div>
  );
}

const filterParams = { values: zohoCampaignStatusValues };

function createColumnDefs(): ColDef<CampaignListRow>[] {
  return [
    {
      field: 'name',
      headerName: 'Campaign Name',
      cellRenderer: (props: CustomCellRendererProps) => {
        if (props.value !== undefined) {
          return props.value;
        } else {
          return <FontAwesomeIcon icon={faSpinner} className="animate-rotate text-blue-500" />;
        }
      },
      suppressHeaderMenuButton: true
    },
    {
      field: 'campaign_number',
      headerName: 'ID'
    },
    {
      field: 'stage',
      filter: 'agSetColumnFilter',
      filterParams,
      comparator: (a, b) =>
        zohoCampaignStatusValues.indexOf(a) - zohoCampaignStatusValues.indexOf(b),
      suppressHeaderMenuButton: true
    },
    {
      field: 'account.name',
      headerName: 'Account'
    },
    {
      field: 'budget',
      headerName: 'Price',
      valueFormatter: ({ value }) => (value ? formatMoney(value) : ''),
      suppressHeaderMenuButton: true,
      filter: 'agNumberColumnFilter',
      filterParams: {
        allowedCharPattern: '\\d\\-\\,',
        filterOptions: ['equals', 'greaterThan', 'lessThan'],
        maxNumConditions: 2
      }
    },
    {
      cellDataType: 'date',
      field: 'flight_date',
      headerName: 'Start date',
      valueFormatter: params => formatDate(params.data?.flight_date),
      filter: 'agDateColumnFilter',
      filterParams: {
        buttons: ['apply', 'reset'],
        filterOptions: ['equals', 'lessThan', 'greaterThan', 'inRange'],
        maxNumConditions: 1
      }
    },
    {
      cellDataType: 'date',
      field: 'end_date',
      headerName: 'End date',
      valueFormatter: params => formatDate(params.data?.end_date),
      filter: 'agDateColumnFilter',
      filterParams: {
        buttons: ['apply', 'reset'],
        filterOptions: ['equals', 'lessThan', 'greaterThan', 'inRange'],
        maxNumConditions: 1
      }
    },
    {
      field: 'line_item_price_allocated',
      headerName: 'Line Item Price Allocated',
      cellDataType: 'number'
    },
    {
      field: 'line_items_count',
      headerName: 'Line Items Count',
      cellDataType: 'number'
    },
    {
      field: 'line_items_with_platforms_count',
      headerName: 'Line Items With Platforms Count',
      cellDataType: 'number'
    },
    {
      field: 'line_items_with_media_plans_count',
      headerName: 'Line Items With Media Plans Count',
      cellDataType: 'number'
    },
    {
      field: 'total_media_budget_allocated',
      headerName: 'Total Media Budget Allocated',
      cellDataType: 'number'
    },
    {
      field: 'total_units_allocated',
      headerName: 'Total Units Allocated',
      cellDataType: 'number'
    },
    {
      field: 'total_media_plans_count',
      headerName: 'Total Media Plans Count',
      cellDataType: 'number'
    },
    {
      field: 'total_media_plans_linked',
      headerName: 'Total Media Plans Linked',
      cellDataType: 'number'
    }
  ];
}

function getCurrentSort(sortModel: SortModelItem[]): CampaignSort | undefined {
  if (sortModel.length === 0) return undefined;
  const { sort, colId } = sortModel[0];
  if (campaignSortColumns.includes(colId)) {
    // TODO[mk]: properly refine type
    return { colId: colId as CampaignSortColumn, sort };
  } else return undefined;
}

function defaultFilters(): CampaignListRequest {
  return { page: 1, filters: {} };
}

function isMetaKeyPressed(event: Event | null | undefined): boolean {
  return event instanceof PointerEvent && (event.metaKey || event.ctrlKey);
}
