import React from 'react';

export function Crosshairs() {
  return (
    <svg height="16" width="16" fill="none" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_11373_570637)">
        <path
          d="M15.2508 8.54922H13.7008C13.4258 11.2492 11.2508 13.4242 8.55078 13.6742V15.2492C8.55078 15.5492 8.30078 15.8242 7.97578 15.8242C7.67578 15.8242 7.40078 15.5742 7.40078 15.2492V13.6992C4.70078 13.4242 2.55078 11.2742 2.25078 8.57422H0.750781C0.450781 8.57422 0.175781 8.32422 0.175781 7.99922C0.175781 7.69922 0.425781 7.42422 0.750781 7.42422H2.30078C2.55078 4.67422 4.72578 2.47422 7.45078 2.22422V0.74922C7.45078 0.44922 7.70078 0.17422 8.02578 0.17422C8.32578 0.17422 8.60078 0.42422 8.60078 0.74922V2.22422C11.3258 2.49922 13.5008 4.69922 13.7508 7.42422H15.2508C15.5508 7.42422 15.8258 7.67422 15.8258 7.99922C15.8258 8.29922 15.5508 8.54922 15.2508 8.54922ZM8.57578 3.37422V3.47422C8.57578 3.77422 8.32578 4.04922 8.00078 4.04922C7.70078 4.04922 7.42578 3.79922 7.42578 3.47422V3.37422C5.32578 3.62422 3.65078 5.32422 3.40078 7.44922H3.42578C3.72578 7.44922 4.00078 7.69922 4.00078 8.02422C4.00078 8.32422 3.75078 8.59922 3.42578 8.59922H3.40078C3.67578 10.6992 5.32578 12.3492 7.40078 12.5992V12.5742C7.40078 12.2742 7.65078 11.9992 7.97578 11.9992C8.27578 11.9992 8.55078 12.2492 8.55078 12.5742V12.5992C10.6258 12.3492 12.2758 10.6992 12.5508 8.59922H12.5258C12.2258 8.59922 11.9508 8.34922 11.9508 8.02422C11.9508 7.72422 12.2008 7.44922 12.5258 7.44922H12.5508C12.3508 5.32422 10.6758 3.62422 8.57578 3.37422Z"
          fill="#111928"
        />
        <path
          d="M8.00156 10.1484C6.80156 10.1484 5.85156 9.17344 5.85156 7.99844C5.85156 6.79844 6.82656 5.84844 8.00156 5.84844C9.20156 5.84844 10.1516 6.82344 10.1516 7.99844C10.1516 9.19844 9.20156 10.1484 8.00156 10.1484ZM8.00156 6.97344C7.42656 6.97344 6.97656 7.44844 6.97656 7.99844C6.97656 8.57344 7.45156 9.02344 8.00156 9.02344C8.57656 9.02344 9.02656 8.54844 9.02656 7.99844C9.02656 7.42344 8.57656 6.97344 8.00156 6.97344Z"
          fill="#111928"
        />
      </g>
      <defs>
        <clipPath id="clip0_11373_570637">
          <rect height="16" width="16" fill="white" transform="matrix(1 0 0 -1 0 16)" />
        </clipPath>
      </defs>
    </svg>
  );
}
