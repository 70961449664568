import React from 'react';

export function PowerSwitch({ fill }: { fill: string }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="14" viewBox="0 0 12 14" fill="none">
      <path
        d="M5.99995 8.29082C6.26245 8.29082 6.50308 8.07207 6.50308 7.7877V1.09395C6.50308 0.831445 6.28433 0.59082 5.99995 0.59082C5.73745 0.59082 5.49683 0.80957 5.49683 1.09395V7.80957C5.5187 8.07207 5.73745 8.29082 5.99995 8.29082Z"
        fill={fill}
      />
      <path
        d="M8.42812 2.12209C8.1875 2.01271 7.88125 2.12209 7.77187 2.36271C7.6625 2.60334 7.77188 2.90959 8.0125 3.01896C9.7625 3.80646 10.9 5.57834 10.9 7.50334C10.9 10.2158 8.69062 12.4252 5.97812 12.4252C3.26562 12.4252 1.07812 10.2158 1.07812 7.50334C1.07812 5.57834 2.21563 3.82834 3.96563 3.01896C4.20625 2.90959 4.31562 2.62521 4.20625 2.36271C4.09687 2.12209 3.8125 2.01271 3.55 2.12209C1.47187 3.08459 0.09375 5.18459 0.09375 7.50334C0.09375 10.7627 2.74063 13.4096 6 13.4096C9.25937 13.4096 11.9062 10.7627 11.9062 7.50334C11.9062 5.18459 10.5281 3.08459 8.42812 2.12209Z"
        fill={fill}
      />
    </svg>
  );
}
