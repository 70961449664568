import React from 'react';
import clsx from 'clsx';
import { Circle } from '../icons/circle';
import { CheckInCircle } from '../icons/check-in-circle';
import { MinusInCircle } from '../icons/minus-in-circle';
import { LineItemIcon, MediaPlanIcon } from './nav-icons';
import {
  calcCountAllocatedLabel,
  calcPercentAllocatedLabel
} from './tables/cell-renderers/amount-allocated-indicators';
import { calcProportion } from './tables/cell-renderers/utils';
import { LineItemPlan } from '../../../shared/src/line-item-types';
import { TooltipProps } from '../features/line-items/all-line-items-v2';

export type Property = {
  label: string;
  percentage?: number;
  value?: string;
};

type Props = {
  entityIcon: React.ReactNode;
  heading: string;
  properties: Property[];
};
export function PlanStatusTooltip({ entityIcon, heading, properties }: Props) {
  return (
    <div className="shadow-b-lg flex w-[300px] flex-col items-center gap-2 rounded bg-white p-2">
      <div className="flex items-center gap-2.5">
        {entityIcon}
        <span className="text-base font-semibold text-gray-600">{heading}</span>
      </div>
      {properties.map(property => (
        <PropertyStatus key={property.label} {...property} />
      ))}
    </div>
  );
}

export function PropertyStatus({ label, percentage, value }: Property) {
  const bgColor = calcBgColor(percentage);
  const icon = calcIcon(percentage);
  const txtColor = calcTxtColor(percentage);
  const borderColor = calcBorderColor(percentage);

  return (
    <div
      className={clsx(
        'flex w-full items-center justify-between rounded px-2 py-0.5 text-xs',
        bgColor
      )}>
      <div className="flex items-center gap-1 font-medium text-gray-700">
        {icon}
        {label}
      </div>
      <div className={clsx('border-l-[1px] pl-1', txtColor, borderColor)}>{value}</div>
    </div>
  );
}

function calcIcon(percentage: number | undefined) {
  if (percentage === undefined) return <MinusInCircle />;
  if (percentage === 100) return <CheckInCircle />;
  return <Circle />;
}

function calcBgColor(percentage: number | undefined) {
  if (percentage === undefined) return 'bg-gray-100';
  if (percentage === 100) return 'bg-lime-100';
  return 'bg-yellow-50';
}

function calcTxtColor(percentage: number | undefined) {
  if (percentage === undefined) return null;
  if (percentage === 100) return 'text-lime-700';
  return 'text-yellow-700';
}

function calcBorderColor(percentage: number | undefined) {
  if (percentage === undefined) return null;
  if (percentage === 100) return 'border-l-lime-400';
  return 'bg-l-yellow-200';
}

export function CampaignLineItemsPlanStatusTooltip({ value }: TooltipProps<Property[]>) {
  if (!value) return null;
  return (
    <PlanStatusTooltip entityIcon={<LineItemIcon />} heading="Line Items" properties={value} />
  );
}

export function CampaignMediaPlansPlanStatusTooltip({ value }: TooltipProps<Property[]>) {
  if (!value) return null;
  return (
    <PlanStatusTooltip entityIcon={<MediaPlanIcon />} heading="Media Plans" properties={value} />
  );
}

export function LineItemPlanStatusTooltip({ value }: TooltipProps<Property[]>) {
  if (!value) return null;
  return (
    <PlanStatusTooltip entityIcon={<MediaPlanIcon />} heading="Media Plans" properties={value} />
  );
}

export function lineItemPlanStatusTooltipValueGetter(props: TooltipProps<LineItemPlan>) {
  if (!props.value || !props.data) return null;
  const { media_platforms } = props.data;
  if (media_platforms.length === 0) {
    return [{ label: 'Pending platform assignment' }];
  }
  const {
    media_budget,
    num_media_buys,
    num_media_plans,
    delivery: { target_units }
  } = props.data;
  const { media_budget_allocated, units_allocated } = props.value;

  const budgetAllocationPct =
    media_budget > 0 ? calcProportion(media_budget_allocated, media_budget) : undefined;
  const unitAllocationPct =
    target_units > 0 ? calcProportion(units_allocated, target_units) : undefined;
  const mediaPlanWithPlatformBuyPct =
    num_media_plans > 0 ? calcProportion(num_media_buys, num_media_plans) : undefined;

  if (
    budgetAllocationPct === undefined ||
    unitAllocationPct === undefined ||
    mediaPlanWithPlatformBuyPct === undefined
  ) {
    return [
      {
        label: 'Pending line item plan'
      }
    ];
  }

  return [
    {
      label: 'Budget allocation',
      percentage: budgetAllocationPct,
      value: calcPercentAllocatedLabel(budgetAllocationPct)
    },
    {
      label: 'Unit allocation',
      percentage: unitAllocationPct,
      value: calcPercentAllocatedLabel(unitAllocationPct)
    },
    {
      label: 'Media plans w/ platform buys',
      percentage: mediaPlanWithPlatformBuyPct,
      value: calcCountAllocatedLabel(mediaPlanWithPlatformBuyPct)
    }
  ];
}
