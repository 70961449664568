import React from 'react';

export function Circle() {
  return (
    <svg height="14" width="14" fill="none" viewBox="0 0 14 14" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_6562_5946)">
        <path
          d="M7.00005 13.6282C3.34692 13.6282 0.393799 10.6532 0.393799 7.00005C0.393799 3.34692 3.34692 0.393799 7.00005 0.393799C10.6532 0.393799 13.6282 3.34692 13.6282 7.00005C13.6282 10.6532 10.6532 13.6282 7.00005 13.6282ZM7.00005 1.37817C3.8938 1.37817 1.37817 3.8938 1.37817 7.00005C1.37817 10.1063 3.91567 12.6438 7.02192 12.6438C10.1282 12.6438 12.6657 10.1063 12.6657 7.00005C12.6438 3.8938 10.1063 1.37817 7.00005 1.37817Z"
          fill="#A16207"
        />
      </g>
      <defs>
        <clipPath id="clip0_6562_5946">
          <rect height="14" width="14" fill="white" rx="6" />
        </clipPath>
      </defs>
    </svg>
  );
}
