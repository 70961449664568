import { PostHog, usePostHog } from 'posthog-js/react';
import { useEffect, useMemo } from 'react';
import { CombinedLineItem, CombinedMediaPlan } from '../../store/strategy-combiner';
import { Campaign } from 'shared/src/campaign-types';

type BravoEntity = 'account' | 'campaign' | 'line_item' | 'media_plan' | 'platform_buy';

type DetailPageViewedEvent = {
  name: 'detail_page_viewed';
  entity_type: BravoEntity;
  entity_id: string;
  alternative_entity_id?: string;
  source?: string;
  $groups: {
    [key in BravoEntity]?: string;
  };
};

type ListingPageViewedEvent = {
  name: 'listing_page_viewed';
  entity_type: 'campaign' | 'line_item' | 'platform_buy';
  source?: string;
};

type EntityCreatedEvent = {
  name: 'entity_created';
  entity_type: 'line_item' | 'media_plan' | 'platform_buy';
  entity_id: string;
  creation_method: 'new' | 'duplication';
  $groups: {
    [key in BravoEntity]?: string;
  };
};

type PublishStartedEvent = {
  name: 'publish_started';
  entity_type: BravoEntity;
  entity_id: string;
  change_count: number;
  entity_count: number;
  $groups: {
    [key in BravoEntity]?: string;
  };
};

type PublishCompletedEvent = {
  name: 'publish_completed';
  entity_type: BravoEntity;
  entity_id: string;
  change_count: number;
  entity_count: number;
  $groups: {
    [key in BravoEntity]?: string;
  };
};

type PublishEntityEvent = {
  name: 'entity_published';
  entity_type: 'line_item' | 'media_plan' | 'platform_buy';
  entity_id: string;
  change_type: 'new' | 'update' | 'delete';
  change_count: number;
  $groups: {
    [key in BravoEntity]?: string;
  };
};

type BravoEvent =
  | DetailPageViewedEvent
  | ListingPageViewedEvent
  | EntityCreatedEvent
  | PublishStartedEvent
  | PublishCompletedEvent
  | PublishEntityEvent;

export function useCaptureCampaignView(campaign?: Campaign) {
  const captureEvent = useCaptureEvent();
  useEffect(() => {
    if (campaign) {
      captureEvent({
        name: 'detail_page_viewed',
        entity_type: 'campaign',
        entity_id: campaign.campaign_id,
        alternative_entity_id: campaign.campaign_number,
        $groups: { account: campaign.account.id, campaign: campaign.campaign_id }
      });
    }
  }, [campaign, captureEvent]);
}

export function useCaptureLineItemView(campaign?: Campaign, lineItem?: CombinedLineItem) {
  const captureEvent = useCaptureEvent();
  useEffect(() => {
    if (campaign && lineItem?.id) {
      captureEvent({
        name: 'detail_page_viewed',
        entity_type: 'line_item',
        entity_id: lineItem.id,
        $groups: {
          account: campaign.account.id,
          campaign: campaign.campaign_id,
          line_item: lineItem.id
        }
      });
    }
  }, [campaign, lineItem?.id, captureEvent]);
}

export function useCaptureMediaPlanView(
  campaign?: Campaign,
  lineItem?: CombinedLineItem,
  mediaPlan?: CombinedMediaPlan
) {
  const captureEvent = useCaptureEvent();
  useEffect(() => {
    if (campaign && lineItem?.id && mediaPlan?.id) {
      captureEvent({
        name: 'detail_page_viewed',
        entity_type: 'media_plan',
        entity_id: mediaPlan.id,
        $groups: {
          account: campaign.account.id,
          campaign: campaign.campaign_id,
          line_item: lineItem.id,
          media_plan: mediaPlan.id,
          ...(mediaPlan.media_buy ? { platform_buy: mediaPlan.media_buy.id } : {})
        }
      });
    }
  }, [captureEvent, campaign, lineItem?.id, mediaPlan?.id, mediaPlan?.media_buy]);
}

export function useCaptureListingPageView(entityType: 'campaign' | 'line_item' | 'platform_buy') {
  const captureEvent = useCaptureEvent();
  useEffect(() => {
    captureEvent({ name: 'listing_page_viewed', entity_type: entityType });
  }, [captureEvent, entityType]);
}

export function useCaptureLineItemCreate() {
  const captureEvent = useCaptureEvent();

  function captureCreate(
    lineItemId: string,
    creation_method: 'new' | 'duplication',
    campaign?: Campaign
  ) {
    captureEvent({
      name: 'entity_created',
      entity_type: 'line_item',
      entity_id: lineItemId,
      creation_method,
      $groups: {
        account: campaign?.account.id,
        campaign: campaign?.campaign_id,
        line_item: lineItemId
      }
    });
  }

  return captureCreate;
}

export function useCaptureMediaPlanCreate() {
  const captureEvent = useCaptureEvent();

  function captureCreate(
    mediaPlanId: string,
    platformBuyId?: string,
    lineItem?: CombinedLineItem,
    campaign?: Campaign
  ) {
    captureEvent({
      name: 'entity_created',
      creation_method: 'new',
      entity_type: 'media_plan',
      entity_id: mediaPlanId,
      $groups: {
        account: campaign?.account.id,
        campaign: campaign?.campaign_id,
        line_item: lineItem?.id,
        media_plan: mediaPlanId,
        platform_buy: platformBuyId
      }
    });
  }

  return captureCreate;
}

function useCaptureEvent() {
  const posthog = usePostHog();
  return useMemo(() => {
    return (event: BravoEvent) => {
      const { name, ...properties } = event;
      identifyGroups(posthog, event);
      posthog.capture(name, properties);
    };
  }, [posthog]);
}

function identifyGroups(posthog: PostHog, event: BravoEvent) {
  if ('$groups' in event) {
    const { account, campaign, line_item, platform_buy, media_plan } = event.$groups;
    if (account) {
      identifyGroup(posthog, 'account', account);
    }
    if (campaign) {
      identifyGroup(posthog, 'campaign', campaign);
    }
    if (line_item) {
      identifyGroup(posthog, 'line_item', line_item);
    }
    if (platform_buy) {
      identifyGroup(posthog, 'platform_buy', platform_buy);
    }
    if (media_plan) {
      identifyGroup(posthog, 'media_plan', media_plan);
    }
  }
}

function identifyGroup(posthog: PostHog, type: BravoEntity, value: string) {
  posthog.capture('$groupidentify', {
    $group_type: type,
    $group_key: value,
    $group_set: { value }
  });
}
