import React, { useEffect, useMemo } from 'react';
import { Route, Routes, useParams } from 'react-router-dom';
import { trpc } from '../../utils/trpc-client';
import { Strategy } from 'shared/src/types';
import { useStore } from '../../store/store';
import { getStrategy } from '../../store/state-fns';
import { combineStrategy } from '../../store/strategy-combiner';
import { StrategyPage } from './strategy-page';
import { LineItemPage } from '../line-items/line-item-page';
import { MediaPlanPage } from '../media-buys/media-plan-page';
import { StrategyContextProvider } from '../line-items/strategy-context';
import { useAuth } from '../../auth/use-auth';
import { usePostHog } from 'posthog-js/react';
import { removeInvalidItems } from '../../store/strategy-validator';

export function CampaignStrategyPage() {
  const { zohoCampaignId } = useParams<{ zohoCampaignId: string }>();
  const strategy = trpc.strategy.useQuery({ zoho_campaign_id: zohoCampaignId! });
  useLogPendingItems();

  if (strategy.isLoading || !strategy.data) {
    return <div>Loading...</div>;
  }
  return <StrategyRoutes strategy={strategy.data} refetch={strategy.refetch} />;
}

type StrategyRouteProps = {
  strategy: Strategy;
  refetch: () => Promise<unknown>;
};

export function StrategyRoutes({ strategy, refetch }: StrategyRouteProps) {
  const storeStrategy = useStore(state => getStrategy(state, strategy.id));

  const combinedStrategy = useMemo(() => {
    removeInvalidItems(strategy, storeStrategy);
    return combineStrategy(strategy, storeStrategy);
  }, [strategy, storeStrategy]);

  return (
    <StrategyContextProvider strategy={combinedStrategy} refetch={refetch}>
      <Routes>
        <Route path="strategy/*" element={<StrategyPage />} />
        <Route path="strategy/lineitems/:lineItemId/*" element={<LineItemPage />} />
        <Route
          path="strategy/lineitems/:lineItemId/mediaplans/:mediaPlanId/*"
          element={<MediaPlanPage />}
        />
      </Routes>
    </StrategyContextProvider>
  );
}

// TODO[mk]: Using this to track down an issue with pending items
function useLogPendingItems() {
  const { identity } = useAuth();
  const posthog = usePostHog();
  const [logged, setLogged] = React.useState(false);
  useEffect(() => {
    try {
      if (identity && !logged) {
        const pendingItems = localStorage.getItem('media-tool-pending');
        console.log('LOCAL PENDING ITEMS', pendingItems);
        posthog.capture('pending_items_log', { pendingItems });
        setLogged(true);
      }
    } catch (e) {
      console.error('Error logging pending items:', e);
    }
  }, [identity, logged, posthog]);
}
