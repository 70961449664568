import React from 'react';
import { LineItemIcon, MediaPlanIcon } from '../../nav-icons';
import {
  calcProportion,
  PropertyIndicatorContainer,
  ProgressBarContainer,
  PropertyIndicatorLabel,
  PendingPropertyIndicator
} from './utils';
import clsx from 'clsx';

type Props = {
  allocated: number;
  total: number;
};

export function PriceAllocatedIndicator({ allocated, total }: Props) {
  return (
    <PercentAllocatedIndicator allocated={allocated} total={total} pendingLabel="Pending Price" />
  );
}

export function PriceAllocatedIndicatorTooltip({ allocated, total }: Props) {
  if (total === 0) {
    return 'No campaign price in Zoho';
  }
  return <PercentAllocatedTooltip proportion={calcProportion(allocated, total)} />;
}

export function LineItemsWithPlatformsIndicator({ allocated, total }: Props) {
  return (
    <CountAllocatedIndicator
      allocated={allocated}
      total={total}
      pendingLabel={
        <div className="flex gap-1">
          Pending
          <LineItemIcon />
        </div>
      }
    />
  );
}

export function LineItemsWithPlatformsTooltip({ allocated, total }: Props) {
  if (total === 0) {
    return 'Line items not complete';
  }
  return <CountAllocatedTooltip allocated={allocated} total={total} label="line items" />;
}

export function LineItemsWithMediaPlansIndicator({
  allocated,
  total,
  missingPlatform
}: Props & { missingPlatform: boolean }) {
  if (missingPlatform) {
    return <PendingPropertyIndicator label="Pending Platform" />;
  }
  return (
    <CountAllocatedIndicator
      allocated={allocated}
      total={total}
      pendingLabel={
        <div className="flex gap-1">
          Pending
          <LineItemIcon />
        </div>
      }
    />
  );
}

export function LineItemsWithMediaPlansTooltip({
  allocated,
  total,
  missingPlatform
}: Props & { missingPlatform: boolean }) {
  if (missingPlatform) {
    return 'No platform assigned';
  }
  if (total === 0) {
    return 'Line items not complete';
  }
  return <CountAllocatedTooltip allocated={allocated} total={total} label="line items" />;
}

export function BudgetAllocatedIndicator({
  allocated,
  total,
  missingPlatform
}: Props & { missingPlatform: boolean }) {
  if (missingPlatform) {
    return <PendingPropertyIndicator label="Pending Platform" />;
  }
  return (
    <PercentAllocatedIndicator
      allocated={allocated}
      total={total}
      pendingLabel={
        <div className="flex gap-1">
          Pending
          <LineItemIcon />
        </div>
      }
    />
  );
}

export function BudgetAllocatedTooltip({
  allocated,
  total,
  missingPlatform
}: Props & { missingPlatform: boolean }) {
  if (missingPlatform) return 'No platforms assigned';
  if (total === 0) return 'Line items not complete';
  return <PercentAllocatedTooltip proportion={calcProportion(allocated, total)} />;
}

export function UnitsAllocatedIndicator({
  allocated,
  total,
  missingPlatform
}: Props & { missingPlatform: boolean }) {
  if (missingPlatform) {
    return <PendingPropertyIndicator label="Pending Platform" />;
  }
  return (
    <PercentAllocatedIndicator
      allocated={allocated}
      total={total}
      pendingLabel={
        <div className="flex gap-1">
          Pending
          <LineItemIcon />
        </div>
      }
    />
  );
}

export function UnitsAllocatedTooltip({
  allocated,
  total,
  missingPlatform
}: Props & { missingPlatform: boolean }) {
  if (missingPlatform) return 'No platform assigned';
  if (total === 0) return 'Line items not complete';
  return <PercentAllocatedTooltip proportion={calcProportion(allocated, total)} />;
}

export function MediaPlansWithPlatformBuysIndicator({ allocated, total }: Props) {
  return (
    <CountAllocatedIndicator
      allocated={allocated}
      total={total}
      pendingLabel={
        <div className="flex gap-1">
          Pending
          <MediaPlanIcon />
        </div>
      }
    />
  );
}

export function MediaPlansWithPlatformBuysTooltip({ allocated, total }: Props) {
  if (total === 0) {
    return 'No Media Plans';
  }
  return <CountAllocatedTooltip allocated={allocated} total={total} label="media plans" />;
}

export function PercentAllocatedIndicator({
  allocated,
  total,
  pendingLabel
}: Props & { pendingLabel: React.ReactNode }) {
  if (total === 0) {
    return <PendingPropertyIndicator label={pendingLabel} />;
  }
  const pct = calcProportion(allocated, total);
  const label = calcPercentAllocatedLabel(pct);
  return <ProgressIndicator label={label} percentage={pct} />;
}

export function PercentAllocatedTooltip({ proportion }: { proportion: number }) {
  return (
    <div className="flex gap-1">
      <div className="font-semibold">{proportion.toFixed(1)}%</div>
      allocated
    </div>
  );
}

export function CountAllocatedIndicator({
  allocated,
  total,
  pendingLabel
}: Props & { pendingLabel: React.ReactNode }) {
  if (total === 0) {
    return <PendingPropertyIndicator label={pendingLabel} />;
  }
  const pct = calcProportion(allocated, total);
  const label = calcCountAllocatedLabel(pct);
  return <ProgressIndicator label={label} percentage={pct} />;
}

export function CountAllocatedTooltip({ allocated, total, label }: Props & { label: string }) {
  return (
    <div className="flex gap-1">
      <div className="font-semibold">{allocated || 0}</div>
      of {total} {label}
    </div>
  );
}

type IndicatorProps = {
  label: React.ReactNode;
  percentage: number;
};

export function ProgressIndicator({ label, percentage }: IndicatorProps) {
  const bgColor = calcBgColor(percentage);
  const barColor = calcBarColor(percentage);
  const txtColor = calcTxtColor(percentage);

  return (
    <PropertyIndicatorContainer className={bgColor}>
      <PropertyIndicatorLabel className={txtColor}>{label}</PropertyIndicatorLabel>
      <ProgressBarContainer>
        {percentage > 100 ? (
          <>
            <div
              className="rounded-l-[3px] bg-gray-500"
              style={{ width: `${100 - ((percentage - 100) / percentage) * 100}%` }}
            />
            <div
              className={clsx('progress-bar-over-allocated-shadow rounded-r-[3px]', barColor)}
              style={{ width: `${((percentage - 100) / percentage) * 100}%` }}
            />
          </>
        ) : (
          <div
            className={clsx('h-1 rounded-[3px]', barColor)}
            style={{ width: `${percentage}%` }}
          />
        )}
      </ProgressBarContainer>
    </PropertyIndicatorContainer>
  );
}

const calcBgColor = (percentage: number) => {
  if (percentage === 100) return 'bg-lime-50';
  return 'bg-yellow-50';
};

const calcBarColor = (percentage: number) => {
  if (percentage === 100) return 'bg-gradient-to-r from-[#A3E635] to-[#65A30D]';
  return 'bg-gradient-to-r from-[#FBBF24] to-[#CA8A04]';
};

const calcTxtColor = (percentage: number) => {
  if (percentage === 100) return 'text-lime-700';
  return 'text-yellow-700';
};

export function calcPercentAllocatedLabel(percentage: number) {
  if (percentage === 0) return 'Not started';
  if (percentage === 100) return 'Complete';
  if (percentage > 100) return 'Over-allocated';
  return 'Partial';
}
export function calcCountAllocatedLabel(percentage: number) {
  if (percentage === 0) return 'None';
  if (percentage === 100) return 'All';
  return 'Some';
}
