import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { PendingChangesButton } from '../pending-changes/pending-changes-button';
import { StrategyChangelog } from './strategy-changelog';
import { StrategyOverview } from './strategy-overview';
import { useStrategyContext } from '../line-items/strategy-context';
import { NavItem } from '../../components/navitem';
import { TopSection } from '../../components/layout';
import { CampaignBreadcrumbs } from '../shared/breadcrumbs';
import { useCaptureCampaignView } from '../../utils/posthog/analytics';

export function StrategyPage() {
  const { strategy } = useStrategyContext();
  useCaptureCampaignView(strategy.campaign);

  return (
    <div className="flex w-full flex-col">
      <TopSection>
        <CampaignBreadcrumbs campaign={strategy.campaign} />
        <div className="flex-1" />
        <div className="flex items-end justify-between">
          <div className="flex items-end">
            <NavItem to="performance" label="Overview" />
            <NavItem to="changelog" label="Changelog" />
          </div>
        </div>
      </TopSection>
      <PendingChangesButton />
      <Routes>
        <Route path="performance" element={<StrategyOverview />} />
        <Route path="changelog" element={<StrategyChangelog />} />
      </Routes>
    </div>
  );
}
