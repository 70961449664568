import React from 'react';
import { PowerSwitch } from '../../../icons/power-switch';
import { PlatformBuyIcon } from '../../nav-icons';
import { UTCDate } from '@date-fns/utc';
import { differenceInCalendarDays, isAfter, isBefore, isWithinInterval, subDays } from 'date-fns';
import {
  PropertyIndicatorContainer,
  ProgressBarContainer,
  calcProportion,
  PropertyIndicatorLabel,
  PendingPropertyIndicator
} from './utils';
import clsx from 'clsx';

type Props = {
  active: number;
  total: number;
  startDate: UTCDate;
  endDate: UTCDate;
};

export function ActivePlatformBuysIndicator({ active, total, startDate, endDate }: Props) {
  // if the total is zero return the pending state
  if (total === 0) {
    return (
      <PendingPropertyIndicator
        label={
          <div className="flex items-center gap-2">
            Pending
            <PlatformBuyIcon />
          </div>
        }
      />
    );
  }

  // if active > 0 and the start date is in the future, return 'active before dates' state
  if (active === 0 && total > 0 && isBefore(new UTCDate(), startDate)) {
    return (
      <PropertyIndicatorContainer className="bg-gray-100">
        <div className="flex items-center gap-1 text-[13px] leading-[20px] text-gray-600">
          <div className="font-bold">{differenceInCalendarDays(startDate, new UTCDate())}</div>days
          until start
        </div>
        <ProgressBarContainer />
      </PropertyIndicatorContainer>
    );
  }

  const percentage = calcProportion(active, total);
  const label = calcLabel(percentage, startDate, endDate);
  const bgColor = calcBgColor(percentage, startDate, endDate);
  const txtColor = calcTxtColor(percentage, startDate, endDate);
  const iconColor = calcIconColor(percentage, startDate, endDate);
  const barColor = calcBarColor(startDate, endDate);
  const shadowColor = calcShadow(percentage, startDate, endDate);

  return (
    <PropertyIndicatorContainer className={clsx(bgColor, shadowColor)}>
      <PropertyIndicatorLabel className={txtColor}>
        <PowerSwitch fill={iconColor} />
        {label}
      </PropertyIndicatorLabel>
      <ProgressBarContainer>
        <div
          className={clsx('h-1 rounded-[3px] bg-gradient-to-r', barColor)}
          style={{ width: `${percentage}%` }}
        />
      </ProgressBarContainer>
    </PropertyIndicatorContainer>
  );
}

function calcLabel(percentage: number, startDate: UTCDate, endDate: UTCDate) {
  if (isBefore(new UTCDate(), startDate) && percentage > 0) return 'Active before dates';
  if (isAfter(new UTCDate(), endDate) && percentage > 0) return 'Active after dates';
  if (percentage === 100) return 'All active';
  if (percentage === 0) return 'None active';
  return 'Some active';
}

function calcBgColor(percentage: number, startDate: UTCDate, endDate: UTCDate) {
  if (
    (isBefore(new UTCDate(), startDate) && percentage > 0) ||
    (isAfter(new UTCDate(), endDate) && percentage > 0)
  )
    return 'bg-red-500';
  if (percentage === 100) return 'bg-blue-500';
  if (percentage === 0) return 'bg-gray-100';
  return 'bg-blue-200';
}

function calcTxtColor(percentage: number, startDate: UTCDate, endDate: UTCDate) {
  if (
    (isBefore(new UTCDate(), startDate) && percentage > 0) ||
    (isAfter(new UTCDate(), endDate) && percentage > 0) ||
    percentage === 100
  )
    return 'text-white';
  if (percentage === 0) return 'text-gray-600';
  return 'text-blue-800';
}

function calcIconColor(percentage: number, startDate: UTCDate, endDate: UTCDate) {
  if (
    (isBefore(new UTCDate(), startDate) && percentage > 0) ||
    (isAfter(new UTCDate(), endDate) && percentage > 0) ||
    percentage === 100
  )
    return '#DBEAFE';
  if (percentage === 0) return '#6B7280';
  return '#3B82F6';
}

const calcBarColor = (startDate: UTCDate, endDate: UTCDate) => {
  if (isBefore(new UTCDate(), startDate) || isAfter(new UTCDate(), endDate))
    return 'from-[#EC3B3E] to-[#CF0205]';
  return 'from-[#3B6FEC] to-[#2B00FF]';
};

const calcShadow = (percentage: number, startDate: UTCDate, endDate: UTCDate) => {
  return percentage === 100 && isAfter(new UTCDate(), startDate) && isBefore(new UTCDate(), endDate)
    ? 'active-progress-indicator-shadow'
    : null;
};

export function ActivePlatformBuysTooltip({ total, active, startDate, endDate }: Props) {
  if (total === 0) return 'No Media Plans with Platform Buys';
  return (
    <div className="flex flex-col items-center">
      <NumActive active={active} total={total} startDate={startDate} endDate={endDate} />
      <LineItemDate startDate={startDate} endDate={endDate} />
    </div>
  );
}

function NumActive({ active, total, startDate, endDate }: Props) {
  if (active > 0 || isWithinInterval(new UTCDate(), { start: startDate, end: endDate })) {
    const yesterday = subDays(new UTCDate(), 1).toLocaleDateString('en-US', {
      month: 'short',
      day: 'numeric',
      year: 'numeric'
    });
    return (
      <div className="flex gap-1">
        <div className="font-semibold">{active}</div> of {total} actively spending as of {yesterday}
      </div>
    );
  }
  return null;
}

function LineItemDate({ startDate, endDate }: { startDate: UTCDate; endDate: UTCDate }) {
  if (isBefore(new UTCDate(), startDate)) {
    return `Line Item's start date is ${startDate.toLocaleDateString('en-US', { month: 'short', day: 'numeric', year: 'numeric' })}`;
  }
  if (isAfter(new UTCDate(), endDate)) {
    return `Line Item's end date was ${endDate.toLocaleDateString('en-US', { month: 'short', day: 'numeric', year: 'numeric' })}`;
  }
  return null;
}
