import React, { ReactNode } from 'react';
import { LineItemsTable, LineItemTableData } from '../line-items/line-items-table';
import { useStore } from '../../store/store';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { PacingDrawer } from '../line-items/pacing-drawer';
import { formatDate } from 'shared/src/date-utils';
import { faCopy, faList, faTrashCan, faUser } from '@fortawesome/pro-light-svg-icons';
import { useStrategyContext } from '../line-items/strategy-context';
import { CombinedLineItem, CombinedStrategy } from '../../store/strategy-combiner';
import { ExpandableSidebarSection, PageContentSection, PageSection } from '../../components/layout';
import { DetailItem } from '../../components/detail-item';
import { useCampaignMetrics } from '../media-buys/use-media-buy-metrics';
import { calcCampaignMetrics, lineItemDeliveryMetrics } from '../../metrics/calculate-metrics';
import { CampaignHeroMetrics } from '../campaigns/campaign-hero-metrics';
import { formatCurrencyNoDecimal } from '../../components/table-utils';
import { getColorForZohoStage } from '../../utils/color-utils';
import { clsx } from 'clsx';
import { LineItemMetric } from 'shared/src/metrics-types';
import { campaignTitle, PageTitle } from '../shared/page-title';
import { showDuplicatedItemsToast } from '../../components/duplicated-items-toast';
import { LineItemDeleteModal } from '../line-items/line-item-delete-modal';
import { Tooltip } from 'react-tooltip';
import { v4 as uuid } from 'uuid';
import { useCaptureLineItemCreate } from '../../utils/posthog/analytics';
import {
  filterAdOps,
  filterAnalysts,
  filterClientSuccessDirectors,
  filterMediaTraders,
  filterSeniorMediaTraders
} from '../../utils/user-utils';
import { Campaign } from 'shared/src/campaign-types';

export function StrategyOverview() {
  const { strategy } = useStrategyContext();
  const { data } = useCampaignMetrics(strategy.campaign.campaign_id);
  const metrics = calcCampaignMetrics(data, strategy.line_items);

  return (
    <>
      <PageTitle title={campaignTitle(strategy.campaign.name)} />
      <PageSection>
        <PageContentSection>
          <PerformanceHeader campaign={strategy.campaign} />
          {metrics && <CampaignHeroMetrics metrics={metrics} />}
          <LineItemsSection data={data} strategy={strategy} />
        </PageContentSection>
        <ExpandableSidebarSection
          sections={[
            {
              name: 'details',
              header: 'Details',
              icon: faList,
              render: () => <DetailsSection campaign={strategy.campaign} />
            },
            {
              name: 'users',
              header: 'Role Assignments',
              icon: faUser,
              render: () => <AssignmentsSection campaign={strategy.campaign} />
            }
          ]}
        />
      </PageSection>
      <PacingDrawer strategy={strategy} />
    </>
  );
}

type Props = {
  strategy: CombinedStrategy;
  data: LineItemMetric[];
};

function LineItemsSection({ strategy, data }: Props) {
  const [selectedRows, setSelectedRows] = React.useState<LineItemTableData[]>([]);
  const [deleteModalOpen, setDeleteModalOpen] = React.useState<boolean>(false);
  const duplicateLineItems = useStore(state => state.duplicateLineItems);
  const metrics = lineItemDeliveryMetrics(data, strategy.line_items);
  const captureCreateEvent = useCaptureLineItemCreate();

  // TODO[mk] Extract this to a hook
  function handleDuplicateLineItems(lineItems: CombinedLineItem[]) {
    if (lineItems.length > 0) {
      const idMapping = Object.fromEntries(lineItems.map(i => i.id).map(i => [i, uuid()]));
      duplicateLineItems(lineItems, idMapping);
      for (const lineItem of lineItems) {
        const duplicateId = idMapping[lineItem.id];
        captureCreateEvent(duplicateId, 'duplication', strategy.campaign);
      }
    }
  }

  function handleDuplicate() {
    if (selectedRows.length > 0) {
      handleDuplicateLineItems(selectedRows);
      selectedRows.length > 1 && showDuplicatedItemsToast(selectedRows.length);
    }
  }

  function handleKeyboardDuplicate(currentRow: LineItemTableData) {
    const otherRows = selectedRows.filter(row => row.id !== currentRow.id);
    handleDuplicateLineItems([...otherRows, currentRow]);
  }

  function handleDelete() {
    if (selectedRows.length > 0) {
      setDeleteModalOpen(true);
    }
  }

  const disableDelete = selectedRows.some(item => item.media_plans.length > 0);

  return (
    <div className="flex h-full flex-col">
      <div className="flex items-center justify-between py-2">
        <div className="pr-16 text-lg font-bold">Line Items</div>
        {selectedRows.length > 0 && (
          <div className="flex">
            <LineItemBulkAction
              colorClasses="text-blue-600 hover:bg-blue-50"
              icon={<FontAwesomeIcon icon={faCopy} />}
              onClick={handleDuplicate}
              label="Duplicate"
            />
            <LineItemBulkAction
              colorClasses="text-red-600 hover:bg-red-50"
              disabled={disableDelete}
              icon={<FontAwesomeIcon icon={faTrashCan} />}
              onClick={handleDelete}
              label="Delete"
              tooltipId="delete-line-item-tooltip"
            />
            {disableDelete && (
              <Tooltip
                id="delete-line-item-tooltip"
                place="left-start"
                style={{
                  backgroundColor: '#111827',
                  borderRadius: '5px',
                  maxWidth: 215,
                  position: 'fixed',
                  zIndex: 1
                }}>
                <div className="text-sm">
                  <div className="font-semibold">
                    Cannot delete line items with existing Media Plans.
                  </div>
                  <div>One or more selected line items has media plans.</div>
                </div>
              </Tooltip>
            )}
          </div>
        )}
      </div>
      <LineItemsTable
        lineItems={strategy.line_items ?? []}
        mediaTraders={filterMediaTraders(strategy.campaign.team)}
        setSelectedRows={setSelectedRows}
        handleKeyboardDuplicate={handleKeyboardDuplicate}
        hideRowActions={selectedRows.length > 0}
        metrics={metrics}
      />
      <LineItemDeleteModal
        open={deleteModalOpen}
        setOpen={setDeleteModalOpen}
        lineItemsToDelete={selectedRows}
      />
    </div>
  );
}

type LineItemBulkActionProps = {
  colorClasses: string;
  disabled?: boolean;
  icon: ReactNode;
  onClick: () => void;
  label: string;
  tooltipId?: string;
};

function LineItemBulkAction({
  colorClasses,
  disabled = false,
  icon,
  onClick,
  label,
  tooltipId
}: LineItemBulkActionProps) {
  return (
    <div>
      <button
        data-tooltip-id={tooltipId}
        className={clsx(
          `flex items-center gap-2 rounded-md px-3 py-1 text-sm ${!disabled ? colorClasses : ''}`,
          disabled ? 'text-gray-400' : ''
        )}
        disabled={disabled}
        onClick={onClick}>
        {icon}
        <div>{label}</div>
      </button>
    </div>
  );
}

function DetailsSection({ campaign }: { campaign: Campaign }) {
  return (
    <div className="flex flex-col">
      <DetailItem label="CN Number" value={campaign.campaign_number} />
      <DetailItem label="Created on" value={formatDate(campaign.created_time)} />
      <DetailItem label="Start Date" value={formatDate(campaign.flight_date)} />
      <DetailItem label="End Date" value={formatDate(campaign.end_date)} />
      <DetailItem label="Account" value={campaign.account.name} />
      <DetailItem label="Agency" value={campaign.agency?.name || '-'} />
      <DetailItem label="Campaign Goals" value={campaign.goals_kpis} />
      <DetailItem label="Campaign Stage" value={campaign.stage} />
      <DetailItem
        label="New Business"
        value={(campaign.new_business && (campaign.new_business ? 'Yes' : 'No')) || '-'}
      />
    </div>
  );
}

function AssignmentsSection({ campaign }: { campaign: Campaign }) {
  const { team, lead_account_owner } = campaign;
  const seniorMediaTraders = filterSeniorMediaTraders(team);
  const csds = filterClientSuccessDirectors(team);
  const analysts = filterAnalysts(team);
  return (
    <div className="flex flex-col">
      <DetailItem label="Account Manager" value={lead_account_owner} />
      <DetailItem
        label="Senior Media Trader"
        value={seniorMediaTraders.length > 0 ? seniorMediaTraders[0].name : null}
      />
      <DetailItem label="Client Success Directors" value={csds.length > 0 ? csds[0].name : null} />
      <DetailItem label="Analytics" value={analysts.length > 0 ? analysts[0].name : null} />
      <DetailItem label="Ad Operations" value={filterAdOps(team).join(', ')} />
      <DetailItem label="Media Traders" value={filterMediaTraders(team).join(', ')} />
    </div>
  );
}

export function PerformanceHeader({ campaign }: { campaign: Campaign }) {
  const price = campaign.budget ? formatCurrencyNoDecimal(campaign.budget) : undefined;

  return (
    <div className="mb-2 flex items-center">
      <div className="mr-8 text-lg font-bold">Campaign Performance</div>
      <div className="mr-4 flex items-center rounded-xl bg-gray-100 p-2 text-sm">
        <div
          className={clsx(
            'mr-2 h-[12px] w-[12px] rounded-full ',
            getColorForZohoStage(campaign.stage)
          )}
        />
        <div className="text-sm">{campaign.stage}</div>
      </div>
      <div className="flex items-center rounded-xl bg-gray-100 p-2 text-sm">
        <div className="mr-1">Price:</div>
        <div className="font-light">{price}</div>
      </div>
    </div>
  );
}
