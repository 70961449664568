import React from 'react';

export function CaretIncrease({ sentiment }: { sentiment: 'positive' | 'negative' }) {
  return (
    <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1.49035 11.5083L8.00894 4.00836L14.509 11.525L1.49035 11.5083Z"
        fill={sentiment === 'positive' ? '#65A30D' : '#B91C1C'}
      />
    </svg>
  );
}
