import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { client } from '../../utils/trpc-client';
import '@ag-grid-community/styles/ag-grid.css';
import '@ag-grid-community/styles/ag-theme-quartz.css';
import {
  ColGroupDef,
  GridApi,
  GridReadyEvent,
  KeyCreatorParams,
  ModuleRegistry,
  SortModelItem,
  StatusPanelDef,
  ValueFormatterParams
} from '@ag-grid-community/core';
import { MenuModule } from '@ag-grid-enterprise/menu';
import { SetFilterModule } from '@ag-grid-enterprise/set-filter';
import { StatusBarModule } from '@ag-grid-enterprise/status-bar';
import { FiltersToolPanelModule } from '@ag-grid-enterprise/filter-tool-panel';
import { ServerSideRowModelModule } from '@ag-grid-enterprise/server-side-row-model';
import { AgGridReact, CustomCellRendererProps, CustomTooltipProps } from '@ag-grid-community/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import { PageTitle } from '../shared/page-title';
import { allUnitPriceTypes, Channel } from 'shared/src/line-item-channels';
import { ChannelCellRenderer, getValueString } from './line-item-table-cell-components';
import { formatDate } from 'shared/src/date-utils';
import {
  LineItemPerformanceListRequest,
  LineItemPerformanceListRow,
  LineItemPerformanceSort,
  LineItemPerformanceSortColumn,
  lineItemPerformanceSortColumns,
  LineItemPlan,
  LineItemsFilterTypes
} from 'shared/src/line-item-types';
import { useChannelRatesContext } from '../strategy/channel-rates-context';
import { formatPercentage, getUnitName } from 'shared/src/line-item-utils';
import { SearchRow } from '../../components/search-bar';
import { autoSizeStrategy } from './line-items-table';
import { showErrorToast } from '../../components/error-toast';
import { defaultColDef, formatCurrency } from '../../components/table-utils';
import { AllLineItemsIcon } from '../../components/nav-icons';
import { getAllLineItemUrlParams, storeAllLineItemUrlParams } from '../../utils/url-utils';
import { TotalStatusPanel } from '../shared/totals-status-panel';
import type { RowClickedEvent } from '@ag-grid-community/core/dist/types/src/events';
import { useCaptureListingPageView } from '../../utils/posthog/analytics';
import { CellContainer, DoneOMeterTooltip } from '../../components/tables/cell-renderers/utils';
import {
  BudgetAllocatedIndicator,
  BudgetAllocatedTooltip,
  MediaPlansWithPlatformBuysIndicator,
  MediaPlansWithPlatformBuysTooltip,
  UnitsAllocatedIndicator,
  UnitsAllocatedTooltip
} from '../../components/tables/cell-renderers/amount-allocated-indicators';
import {
  ActivePlatformBuysIndicator,
  ActivePlatformBuysTooltip
} from '../../components/tables/cell-renderers/active-platform-buys-indicator';
import { PacingIndicator } from '../../components/tables/cell-renderers/pacing-indicator';
import { PercentCompletedIndicator } from '../../components/tables/cell-renderers/percent-completed-indicator';
import { LineItemPercentDifferenceIndicator } from '../../components/tables/cell-renderers/percent-difference-indicator';
import {
  LineItemPlanStatusTooltip,
  lineItemPlanStatusTooltipValueGetter
} from '../../components/plan-status-tooltip';
import { LineItemPlanStatusIndicator } from '../../components/tables/cell-renderers/plan-status-indicators';

ModuleRegistry.registerModules([
  ServerSideRowModelModule,
  MenuModule,
  SetFilterModule,
  StatusBarModule,
  FiltersToolPanelModule
]);

export type CellRendererProps<T> = CustomCellRendererProps<LineItemPerformanceListRow, T>;
export type TooltipProps<T> = CustomTooltipProps<LineItemPerformanceListRow, T>;

export function AllLineItemsV2() {
  const [searchText, setSearchText] = useState<string>('');
  const [gridApi, setGridApi] = useState<GridApi<LineItemPerformanceListRow> | null>(null);
  const { channels } = useChannelRatesContext();
  const columnDefs: ColGroupDef<LineItemPerformanceListRow>[] = useMemo(
    () => createColumnDefs(channels),
    [channels]
  );
  const navigate = useNavigate();
  useCaptureListingPageView('line_item');

  function handleRowClick(clickEvent: RowClickedEvent<LineItemPerformanceListRow>) {
    const { event, data } = clickEvent;
    if (!data) return;
    const url = `/campaigns/${data.campaign_id}/strategy/lineitems/${data.id}/overview`;
    if (isMetaKeyPressed(event)) {
      window.open(url, '_blank');
    } else {
      navigate(url);
    }
  }

  useEffect(() => {
    if (gridApi) {
      gridApi.setGridOption('serverSideDatasource', {
        getRows: async params => {
          const parsedFilters = LineItemsFilterTypes.safeParse(params.request.filterModel);
          if (!parsedFilters.success) {
            console.error('Failed to parse line items filter model', parsedFilters.error);
            return;
          }
          try {
            const sort = getCurrentSort(params.request.sortModel);
            const filters = parsedFilters.data;
            const page = params.request.endRow ? Math.ceil(params.request.endRow / 50) : 1;
            const requestParams = { page, sort, filters, search: searchText };
            const { line_items, total } = await client.lineItemsPerformance.query(requestParams);
            params.success({ rowData: transformRows(line_items), rowCount: total });
            storeAllLineItemUrlParams({ filters, sort });
          } catch (error) {
            console.error('Failed to fetch line items', error);
            showErrorToast();
            return;
          }
        }
      });
    }
  }, [gridApi, searchText]);

  function clearFilters() {
    if (gridApi) {
      gridApi.setFilterModel(null);
      gridApi.applyColumnState({ defaultState: { sort: null } });
    }
  }

  const onGridReady = useCallback((params: GridReadyEvent) => {
    setGridApi(params.api);
    const urlParams = getAllLineItemUrlParams();
    if (urlParams) {
      const { filters, sort } = urlParams;
      params.api.setFilterModel(filters);
      if (sort) params.api.applyColumnState({ state: [sort], defaultState: { sort: null } });
    } else {
      params.api.setFilterModel(defaultFilters());
    }
  }, []);

  const statusBar = useMemo<{
    statusPanels: StatusPanelDef[];
  }>(() => {
    return {
      statusPanels: [{ statusPanel: TotalStatusPanel }]
    };
  }, []);

  return (
    <div className="flex h-full w-full flex-col p-12">
      <PageTitle title="Bravo | Line Items" />
      <SearchRow
        icon={<AllLineItemsIcon />}
        title="All Line Items"
        placeholder="Search By Line Item"
        submitSearch={setSearchText}
      />
      <div
        className="mb-1 flex h-4 cursor-pointer items-center justify-end text-sm text-blue-400 underline"
        onClick={clearFilters}>
        Clear Filters
      </div>
      <div className="ag-theme-quartz" style={{ height: '100%', width: '100%' }}>
        <AgGridReact
          columnDefs={columnDefs}
          defaultColDef={{ ...defaultColDef, cellStyle: { color: '#4B5563' } }}
          rowBuffer={0}
          rowModelType={'serverSide'}
          cacheBlockSize={50}
          cacheOverflowSize={2}
          isExternalFilterPresent={() => true}
          infiniteInitialRowCount={100}
          maxBlocksInCache={10}
          sideBar="filters"
          autoSizeStrategy={autoSizeStrategy}
          onGridReady={onGridReady}
          statusBar={statusBar}
          onRowClicked={handleRowClick}
          tooltipShowDelay={200}
          rowHeight={42}
          headerHeight={32}
          groupHeaderHeight={24}
        />
      </div>
    </div>
  );
}

function transformRows(rows: LineItemPerformanceListRow[]): LineItemPerformanceListRow[] {
  return rows.filter(lineItem => !lineItem.is_deleted);
}

function createColumnDefs(channels: Channel[]): ColGroupDef<LineItemPerformanceListRow>[] {
  const unitPriceTypes = allUnitPriceTypes(channels);

  return [
    {
      headerName: 'Campaign Details',
      children: [
        {
          field: 'campaign_number',
          headerName: 'ID',
          cellRenderer: (props: CustomCellRendererProps) => {
            if (props.value !== undefined) {
              return props.value;
            } else {
              return <FontAwesomeIcon icon={faSpinner} className="animate-rotate text-blue-500" />;
            }
          }
        },
        {
          field: 'campaign_name',
          headerName: 'Campaign Name',
          maxWidth: 500,
          cellRenderer: cellRendererCmp('campaign_name')
        },
        {
          field: 'campaign_stage',
          headerName: 'Stage'
        }
      ]
    },
    {
      headerName: 'Line Item Details',
      children: [
        {
          field: 'name',
          headerName: 'Line Item Name',
          cellRenderer: cellRendererCmp('name'),
          cellStyle: { fontWeight: 'bold' },
          maxWidth: 800
        },
        {
          field: 'price',
          headerName: 'Price',
          cellDataType: 'number',
          valueFormatter: params => formatCurrency(params.data?.price || 0),
          filter: 'agNumberColumnFilter',
          filterParams: {
            allowedCharPattern: '\\d\\-\\,',
            filterOptions: ['equals', 'greaterThan', 'lessThan', 'inRange'],
            maxNumConditions: 1
          }
        },
        {
          cellDataType: 'date',
          field: 'start_date',
          headerName: 'Start Date',
          valueFormatter: params => formatDate(params.data?.start_date),
          filter: 'agDateColumnFilter',
          filterParams: {
            buttons: ['apply', 'reset'],
            filterOptions: ['equals', 'lessThan', 'greaterThan', 'inRange'],
            maxNumConditions: 1
          }
        },
        {
          cellDataType: 'date',
          field: 'end_date',
          headerName: 'End Date',
          valueFormatter: params => formatDate(params.data?.end_date),
          filter: 'agDateColumnFilter',
          filterParams: {
            buttons: ['apply', 'reset'],
            filterOptions: ['equals', 'lessThan', 'greaterThan', 'inRange'],
            maxNumConditions: 1
          }
        }
      ]
    },
    {
      headerName: 'Team',
      children: [
        // TODO add Senior Media Trader here
        {
          field: 'media_traders',
          headerName: 'Media Traders',
          sortable: false,
          valueFormatter: params => {
            return params.data?.media_traders?.map(mt => mt.name).join(',') || '';
          },
          comparator: (a, b) => {
            if (a.length === 0 && b.length === 0) return 0;
            if (a.length === 0) return -1;
            if (b.length === 0) return 1;
            return a[0].name.localeCompare(b[0].name);
          },
          filter: 'agTextColumnFilter',
          filterParams: {
            filterOptions: ['contains', 'notContains'],
            maxNumConditions: 1
          }
        },
        {
          field: 'lead_account_user',
          headerName: 'Lead Account Owner'
        }
      ]
    },
    {
      headerName: 'Line Item Plan',
      children: [
        {
          field: 'plan',
          headerName: 'Plan Status',
          cellRenderer: (props: CellRendererProps<LineItemPlan>) => {
            if (!props.data) return null;
            return (
              <CellContainer>
                <LineItemPlanStatusIndicator result={props.data} />
              </CellContainer>
            );
          },
          tooltipComponent: LineItemPlanStatusTooltip,
          tooltipValueGetter: lineItemPlanStatusTooltipValueGetter,
          resizable: false,
          sortable: false,
          width: 150
        },
        {
          field: 'plan.media_budget_allocated',
          headerName: 'Budget Allocated',
          sortable: false,
          columnGroupShow: 'closed',
          cellRenderer: (props: CellRendererProps<number>) => {
            if (valueDoesNotExist(props.value) || !props.data) return null;
            const { media_budget, media_platforms } = props.data;
            return (
              <CellContainer>
                <BudgetAllocatedIndicator
                  allocated={props.value}
                  total={media_budget}
                  missingPlatform={media_platforms.length === 0}
                />
              </CellContainer>
            );
          },
          tooltipComponent: DoneOMeterTooltip,
          tooltipValueGetter: (props: TooltipProps<number>) => {
            if (valueDoesNotExist(props.value) || !props.data) return null;
            const { media_platforms, media_budget } = props.data;
            return (
              <BudgetAllocatedTooltip
                allocated={props.value}
                total={media_budget}
                missingPlatform={media_platforms.length === 0}
              />
            );
          },

          resizable: false
        },
        {
          field: 'plan.units_allocated',
          headerName: 'Units Allocated',
          sortable: false,
          columnGroupShow: 'closed',
          cellRenderer: (props: CellRendererProps<number>) => {
            if (valueDoesNotExist(props.value) || !props.data) return null;
            const {
              media_platforms,
              delivery: { target_units }
            } = props.data;
            return (
              <CellContainer>
                <UnitsAllocatedIndicator
                  allocated={props.value}
                  total={target_units}
                  missingPlatform={media_platforms.length === 0}
                />
              </CellContainer>
            );
          },
          resizable: false,
          tooltipComponent: DoneOMeterTooltip,
          tooltipValueGetter: (props: TooltipProps<number>) => {
            if (valueDoesNotExist(props.value) || !props.data) return null;
            const {
              media_platforms,
              delivery: { target_units }
            } = props.data;
            return (
              <UnitsAllocatedTooltip
                allocated={props.value}
                total={target_units}
                missingPlatform={media_platforms.length === 0}
              />
            );
          }
        },
        {
          field: 'num_media_buys',
          headerName: 'MPs w/ Platform Buys',
          columnGroupShow: 'closed',
          cellRenderer: (props: CellRendererProps<number>) => {
            if (valueDoesNotExist(props.value) || !props.data) return null;
            const { num_media_plans } = props.data;
            return (
              <CellContainer>
                <MediaPlansWithPlatformBuysIndicator
                  allocated={props.value}
                  total={num_media_plans}
                />
              </CellContainer>
            );
          },
          tooltipComponent: DoneOMeterTooltip,
          tooltipValueGetter: (props: TooltipProps<number>) => {
            if (valueDoesNotExist(props.value) || !props.data) return null;
            const { num_media_plans } = props.data;
            return (
              <MediaPlansWithPlatformBuysTooltip allocated={props.value} total={num_media_plans} />
            );
          },
          resizable: false
        }
      ]
    },
    {
      children: [
        {
          field: 'plan.active_media_plans_count',
          headerName: 'Active Platform Buys',
          cellRenderer: (props: CellRendererProps<number>) => {
            if (valueDoesNotExist(props.value) || !props.data) return null;
            const { num_media_plans, start_date, end_date } = props.data;
            return (
              <CellContainer>
                <ActivePlatformBuysIndicator
                  active={props.value}
                  total={num_media_plans}
                  startDate={start_date}
                  endDate={end_date}
                />
              </CellContainer>
            );
          },
          resizable: false,
          tooltipValueGetter: (props: TooltipProps<number>) => {
            if (valueDoesNotExist(props.value) || !props.data) return null;
            const { num_media_plans, start_date, end_date } = props.data;
            return (
              <ActivePlatformBuysTooltip
                active={props.value}
                total={num_media_plans}
                startDate={start_date}
                endDate={end_date}
              />
            );
          },
          tooltipComponent: DoneOMeterTooltip
        }
      ]
    },
    {
      headerName: 'Line Item Delivery',
      children: [
        {
          field: 'delivery.delivery_pacing',
          headerName: 'Delivery Pacing',
          cellRenderer: (props: CellRendererProps<number>) => {
            if (valueDoesNotExist(props.value)) return null;
            return (
              <CellContainer>
                <PacingIndicator value={props.value} />
              </CellContainer>
            );
          },
          resizable: false
        },
        {
          field: 'delivery.percent_units_delivered',
          headerName: '% Units Delivered',
          cellRenderer: (props: CellRendererProps<number>) => {
            if (!props.data) return null;
            const {
              delivery: { units_delivered, target_units }
            } = props.data;
            return (
              <CellContainer>
                <PercentCompletedIndicator completed={units_delivered} total={target_units} />
              </CellContainer>
            );
          },
          resizable: false
        },
        {
          field: 'delivery.units_delivered',
          headerName: 'Units Delivered',
          columnGroupShow: 'closed',
          cellRenderer: (props: CellRendererProps<number>) => {
            if (!props.data) return null;
            const { unit_price_type } = props.data;
            return `${getNumberString(props.value || 0)} ${getUnitName(unit_price_type.name).abbr}`;
          },
          resizable: false
        },
        {
          field: 'delivery.on_pace_units',
          headerName: 'On-Pace Units',
          columnGroupShow: 'closed',
          cellRenderer: (props: CellRendererProps<number>) => {
            if (valueDoesNotExist(props.value)) return null;
            return getNumberString(props.value || 0);
          },
          resizable: false
        },
        {
          field: 'delivery.target_units',
          headerName: 'Target Units',
          columnGroupShow: 'closed',
          cellRenderer: (props: CellRendererProps<number>) => {
            if (valueDoesNotExist(props.value)) return null;
            return getNumberString(props.value || 0);
          },
          resizable: false
        },
        {
          field: 'delivery.units_remaining',
          headerName: 'Units Remaining',
          columnGroupShow: 'closed',
          cellRenderer: (props: CellRendererProps<number>) => {
            if (valueDoesNotExist(props.value)) return null;
            return getNumberString(props.value || 0);
          },
          resizable: false
        }
      ]
    },
    {
      headerName: 'Line Item Spend',
      children: [
        {
          field: 'spend.spend_pacing',
          headerName: 'Spend Pacing',
          cellRenderer: (props: CellRendererProps<number>) => {
            if (valueDoesNotExist(props.value)) return null;
            return (
              <CellContainer>
                <PacingIndicator value={props.value} />
              </CellContainer>
            );
          },
          resizable: false
        },
        {
          field: 'spend.percent_budget_spent',
          headerName: '% Budget Spent',
          cellRenderer: (props: CellRendererProps<number>) => {
            if (!props.data) return null;
            const {
              media_budget,
              spend: { media_spend }
            } = props.data;
            return (
              <CellContainer>
                <PercentCompletedIndicator completed={media_spend} total={media_budget} />
              </CellContainer>
            );
          },
          resizable: false
        },
        {
          field: 'spend.media_spend',
          headerName: 'Media Spend',
          columnGroupShow: 'closed',
          cellRenderer: (props: CellRendererProps<number>) => {
            if (valueDoesNotExist(props.value)) return null;
            return formatCurrency(props.value);
          },
          resizable: false
        },
        {
          field: 'spend.on_pace_spend',
          headerName: 'On-Pace Spend',
          columnGroupShow: 'closed',
          cellRenderer: (props: CellRendererProps<number>) => {
            if (valueDoesNotExist(props.value)) return null;
            return formatCurrency(props.value);
          },
          resizable: false
        },
        {
          field: 'media_budget',
          headerName: 'Media Budget',
          columnGroupShow: 'closed',
          cellRenderer: (props: CellRendererProps<number>) => {
            if (valueDoesNotExist(props.value)) return null;
            return formatCurrency(props.value);
          },
          resizable: false
        },
        {
          field: 'spend.budget_remaining',
          headerName: 'Budget Remaining',
          columnGroupShow: 'closed',
          cellRenderer: (props: CellRendererProps<number>) => {
            if (valueDoesNotExist(props.value)) return null;
            return formatCurrency(props.value);
          },
          resizable: false
        }
      ]
    },
    {
      headerName: 'Line Item Margin',
      children: [
        {
          field: 'margin.margin_actual',
          headerName: 'Margin Actual',
          cellRenderer: (props: CellRendererProps<number>) => {
            if (valueDoesNotExist(props.value)) return null;
            return (
              <CellContainer>
                <PacingIndicator value={props.value} />
              </CellContainer>
            );
          },
          resizable: false
        },
        {
          field: 'margin.percent_margin_difference',
          headerName: 'Margin Difference',
          cellRenderer: (props: CellRendererProps<number>) => {
            if (valueDoesNotExist(props.value) || !props.data) return null;

            const {
              margin: { margin_actual }
            } = props.data;

            return (
              <CellContainer>
                <LineItemPercentDifferenceIndicator
                  difference={Math.abs(props.value)}
                  label="On-plan"
                  inflection={
                    margin_actual === 0
                      ? undefined
                      : props.value === 0
                        ? 'neutral'
                        : props.value > 0
                          ? { direction: 'increase', sentiment: 'positive' }
                          : { direction: 'decrease', sentiment: 'negative' }
                  }
                />
              </CellContainer>
            );
          },
          resizable: false
        },
        {
          field: 'margin.target_margin',
          headerName: 'Target Margin',
          columnGroupShow: 'closed',
          cellRenderer: (props: CellRendererProps<number>) => {
            if (valueDoesNotExist(props.value)) return null;
            return formatPercentage(props.value);
          },
          resizable: false
        }
      ]
    },
    {
      headerName: 'Line Item Cost',
      children: [
        {
          field: 'cost.percent_unit_cost_difference',
          headerName: 'Unit Cost Difference',
          cellRenderer: (props: CellRendererProps<number>) => {
            if (valueDoesNotExist(props.value)) return null;
            return (
              <CellContainer>
                <LineItemPercentDifferenceIndicator
                  difference={Math.abs(props.value)}
                  label="On-target"
                  inflection={
                    props.value === 0
                      ? 'neutral'
                      : props.value > 0
                        ? { direction: 'increase', sentiment: 'negative' }
                        : { direction: 'decrease', sentiment: 'positive' }
                  }
                />
              </CellContainer>
            );
          },
          resizable: false
        },
        {
          field: 'cost.unit_cost_actual',
          headerName: 'Unit Cost Actual',
          columnGroupShow: 'closed',
          cellRenderer: (props: CellRendererProps<number>) => {
            if (valueDoesNotExist(props.value) || !props.data) return '--';
            const { unit_price_type } = props.data;
            return `${formatCurrency(props.value)} ${unit_price_type.name}`;
          }
        },
        {
          field: 'cost.target_unit_cost',
          headerName: 'Target Unit Cost',
          columnGroupShow: 'closed',
          cellRenderer: (props: CellRendererProps<number>) => {
            if (valueDoesNotExist(props.value) || !props.data) return '--';
            const { unit_price_type } = props.data;
            return `${formatCurrency(props.value)} ${unit_price_type.name}`;
          }
        }
      ]
    },
    {
      headerName: 'Line Item Revenue',
      children: [
        {
          field: 'revenue.percent_revenue_delivered',
          headerName: '% Revenue Delivered',
          cellRenderer: (props: CellRendererProps<number>) => {
            if (valueDoesNotExist(props.value) || !props.data) return null;
            const {
              revenue: { revenue_delivered, target_revenue }
            } = props.data;
            return (
              <CellContainer>
                <PercentCompletedIndicator completed={revenue_delivered} total={target_revenue} />
              </CellContainer>
            );
          },
          resizable: false
        },
        {
          field: 'revenue.revenue_delivered',
          headerName: 'Revenue Delivered',
          columnGroupShow: 'closed',
          cellRenderer: (props: CellRendererProps<number>) => {
            if (valueDoesNotExist(props.value)) return '--';
            return formatCurrency(props.value);
          }
        },
        {
          field: 'revenue.on_pace_revenue',
          headerName: 'On-Pace Revenue',
          columnGroupShow: 'closed',
          cellRenderer: (props: CellRendererProps<number>) => {
            if (valueDoesNotExist(props.value)) return '--';
            return formatCurrency(props.value);
          }
        },
        {
          field: 'revenue.target_revenue',
          headerName: 'Target Revenue',
          columnGroupShow: 'closed',
          cellRenderer: (props: CellRendererProps<number>) => {
            if (valueDoesNotExist(props.value)) return '--';
            return formatCurrency(props.value);
          }
        },
        {
          field: 'revenue.revenue_remaining',
          headerName: 'Revenue Remaining',
          columnGroupShow: 'closed',
          cellRenderer: (props: CellRendererProps<number>) => {
            if (valueDoesNotExist(props.value)) return '--';
            return formatCurrency(props.value);
          }
        }
      ]
    },
    {
      headerName: 'Line Item Details',
      children: [
        {
          field: 'price',
          headerName: 'Price',
          cellDataType: 'number',
          valueFormatter: params => formatCurrency(params.data?.price || 0)
        },
        {
          field: 'channel', // also uses tactic field to derive value (see valueGetter in this column def)
          headerName: 'Channel: Tactic',
          keyCreator: params => params.value.name,
          valueFormatter: params => params.value?.name,
          comparator: (a, b) => a.name.localeCompare(b.name),
          cellRenderer: ChannelCellRenderer,
          filter: 'agSetColumnFilter',
          filterParams: {
            values: channels,
            keyCreator: (params: KeyCreatorParams) => params.value.id,
            valueFormatter: (params: ValueFormatterParams) => params.value.name
          },
          valueGetter: params => {
            if (!params.data) return null;
            return `${params.data.channel.name}: ${params.data.tactic.name}`;
          }
        },
        {
          field: 'unit_price_type',
          headerName: 'Unit Price Type',
          keyCreator: params => params.value.id,
          comparator: (a, b) => a.name.localeCompare(b.name),
          minWidth: 200,
          cellRenderer: cellRendererCmp('unit_price_type'),
          filter: 'agSetColumnFilter',
          filterParams: {
            values: unitPriceTypes,
            keyCreator: (params: KeyCreatorParams) => params.value.id,
            valueFormatter: (params: ValueFormatterParams) => params.value.name
          }
        },
        {
          field: 'geo',
          headerName: 'Geo',
          cellRenderer: cellRendererCmp('geo')
        },
        {
          field: 'targeting',
          headerName: 'Targeting',
          cellRenderer: cellRendererCmp('targeting')
        },
        {
          field: 'audience',
          headerName: 'Audience',
          cellRenderer: cellRendererCmp('audience')
        }
      ]
    }
  ];
}

function valueDoesNotExist(value: number | null | undefined) {
  return value === null || value === undefined;
}

function getNumberString(value: number | undefined): string {
  if (value === undefined) return '--';
  return value.toLocaleString();
}

function getCurrentSort(sortModel: SortModelItem[]): LineItemPerformanceSort | undefined {
  if (sortModel.length === 0) return undefined;
  let { sort, colId } = sortModel[0];
  //Remove parent identifier
  colId = colId.split('.').pop() || colId;
  //Remove trailing _1 if the property is duplicated
  colId = colId.replace(/_1$/, '');
  if (lineItemPerformanceSortColumns.includes(colId)) {
    // TODO[mk]: properly refine type
    return { colId: colId as LineItemPerformanceSortColumn, sort };
  } else return undefined;
}

function defaultFilters(): LineItemPerformanceListRequest {
  return { page: 1, filters: {} };
}

function cellRendererCmp(key: keyof LineItemPerformanceListRow) {
  return (props: CustomCellRendererProps<LineItemPerformanceListRow>) => {
    return getValueString(props.data?.[key]) || <div className="text-blue-500">-</div>;
  };
}

function isMetaKeyPressed(event: Event | null | undefined): boolean {
  return event instanceof PointerEvent && (event.metaKey || event.ctrlKey);
}
