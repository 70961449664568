import React, { ReactNode, useMemo, useState } from 'react';
import { Route, Routes, useNavigate, useParams } from 'react-router-dom';
import { CombinedLineItem, CombinedMediaPlan } from '../../store/strategy-combiner';
import { PendingChangesButton } from '../pending-changes/pending-changes-button';
import {
  faChartLine,
  faGaugeHigh,
  faGears,
  faHand,
  faInfoCircle
} from '@fortawesome/pro-light-svg-icons';
import { useStrategyContext } from './strategy-context';
import {
  ExpandableSidebarSection,
  PageContentSection,
  PageSection,
  TopSection
} from '../../components/layout';
import { NavItem } from '../../components/navitem';
import { MediaPlansTable } from '../media-buys/media-plans-table';
import { ViewLineItemPacing } from './rail/view-line-item-pacing';
import { useLineItemMetrics } from '../media-buys/use-media-buy-metrics';
import { calcLineItemMetrics, calcMediaPlanMetrics } from '../../metrics/calculate-metrics';
import { MediaBuyHeroMetrics } from '../media-buys/media-buy-hero-metrics';
import { MediaBuyGraph } from '../media-buys/media-buy-graph';
import { DisplayMode, DisplayModeSelector } from '../../components/charts/display-mode-selector';
import {
  ChartType,
  ChartTypeSelector,
  Granularity,
  GranularitySelector
} from '../../components/charts/chart-type-selector';
import { convertToLineItem, isCompleted } from 'shared/src/line-item-types';
import { PerformanceRail } from '../shared/performance-rail';
import { DetailsRail } from '../shared/details-rail';
import { lineItemTitle, PageTitle } from '../shared/page-title';
import { LineItemBreadcrumbs } from '../shared/breadcrumbs';
import {
  PlatformBuySearchButton,
  PlatformBuysSearchModal
} from '../media-buys/platform-buys-search-button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { NoPlatformBuysImage } from '../media-buys/no-platform-buys-image';
import { MediaBuyMetrics } from '../../metrics/metrics-types';
import { PlatformBuyMetric } from 'shared/src/metrics-types';
import { NoMediaPlatformsImage } from '../media-buys/no-media-platforms-image';
import { useCaptureLineItemView } from '../../utils/posthog/analytics';

export function LineItemPage() {
  const { strategy } = useStrategyContext();
  const { lineItemId } = useParams();
  const navigate = useNavigate();
  const lineItem = strategy.line_items.find(li => li.id === lineItemId);
  useCaptureLineItemView(strategy.campaign, lineItem);

  if (!lineItem) {
    // If a new line-item change is rejected while on the line-item-page then lineItem will not be set.
    // We handle this here.
    navigate(`/campaigns/${strategy.campaign.campaign_id}/strategy/performance`);
    console.log(`Lineitem is not set, redirecting back to campaign strategy`);
    return null;
  }

  return (
    <div className="flex w-full flex-col">
      <TopSection>
        <LineItemBreadcrumbs campaign={strategy.campaign} name={lineItem.name} />
        <div className="flex-1" />
        <div className="flex items-end">
          <NavItem to="overview" label="Overview" />
        </div>
      </TopSection>
      <PendingChangesButton />
      <Routes>
        <Route path="overview" element={<LineItemOverview lineItem={lineItem} />} />
      </Routes>
    </div>
  );
}

type LineItemOverviewProps = {
  lineItem: CombinedLineItem;
};

function LineItemOverview({ lineItem }: LineItemOverviewProps) {
  const [granularity, setGranularity] = useState<Granularity>('day');
  const { data: lineItemPerformanceData } = useLineItemMetrics(lineItem.id, granularity);
  const lineItemMetrics = useMemo(
    () => calcLineItemMetrics(lineItemPerformanceData, lineItem),
    [lineItemPerformanceData, lineItem]
  );
  const completeLineItem = isCompleted(lineItem) ? convertToLineItem(lineItem) : null;

  return (
    <PageSection>
      <PageTitle title={lineItemTitle(lineItem.name)} />
      <PageContentSection>
        <PlatformBuyLinkingView
          granularity={granularity}
          lineItem={lineItem}
          setGranularity={setGranularity}
          lineItemMetrics={lineItemMetrics}
          lineItemPerformanceData={lineItemPerformanceData}
        />
      </PageContentSection>
      <ExpandableSidebarSection
        sections={[
          ...(completeLineItem && lineItemMetrics
            ? [
                {
                  name: 'execution',
                  header: 'Execution Instructions',
                  icon: faGears,
                  render: (setExpanded: (expanded: string) => void) => (
                    <DetailsRail
                      lineItem={completeLineItem}
                      metrics={lineItemMetrics}
                      setExpanded={setExpanded}
                    />
                  )
                },
                {
                  name: 'performance',
                  header: 'Line Item Performance',
                  icon: faGaugeHigh,
                  render: () => (
                    <PerformanceRail lineItem={completeLineItem} metrics={lineItemMetrics} />
                  )
                }
              ]
            : []),
          ...(completeLineItem && completeLineItem.pacing_type === 'custom'
            ? [
                {
                  name: 'pacing',
                  header: 'Pacing Schedule',
                  icon: faChartLine,
                  render: () => <ViewLineItemPacing lineItem={completeLineItem} />
                }
              ]
            : [])
        ]}
      />
    </PageSection>
  );
}

function PlatformBuyLinkingView({
  lineItem,
  granularity,
  lineItemMetrics,
  lineItemPerformanceData,
  setGranularity
}: {
  lineItem: CombinedLineItem;
  granularity: Granularity;
  lineItemMetrics: MediaBuyMetrics | null;
  lineItemPerformanceData: PlatformBuyMetric[];
  setGranularity: (granularity: Granularity) => void;
}) {
  const [open, setOpen] = useState<boolean>(false);
  const [planToLink, setPlanToLink] = useState<CombinedMediaPlan | null>(null);
  const searchButton = (
    <PlatformBuySearchButton lineItem={lineItem} onClick={() => setOpen(true)} />
  );

  const handleLinkPlatformBuyClick = (plan: CombinedMediaPlan) => {
    setPlanToLink(plan);
    setOpen(true);
  };

  return (
    <>
      {lineItem.media_plans.length > 0 ? (
        <MediaBuysView
          createButton={searchButton}
          granularity={granularity}
          lineItem={lineItem}
          lineItemMetrics={lineItemMetrics}
          lineItemPerformanceData={lineItemPerformanceData}
          onChartGranularityToggle={setGranularity}
          onLinkPlatformBuyClick={handleLinkPlatformBuyClick}
        />
      ) : lineItem.media_platforms && lineItem.media_platforms.length > 0 ? (
        <NoPlatformBuysView searchButton={searchButton} />
      ) : (
        <NoMediaPlatformsView />
      )}
      <PlatformBuysSearchModal
        lineItem={lineItem}
        planToLink={planToLink}
        open={open}
        setOpen={setOpen}
      />
    </>
  );
}

function MediaBuysView({
  granularity,
  lineItem,
  lineItemMetrics,
  lineItemPerformanceData,
  onChartGranularityToggle,
  onLinkPlatformBuyClick,
  createButton
}: {
  createButton: ReactNode;
  granularity: Granularity;
  lineItem: CombinedLineItem;
  lineItemMetrics: MediaBuyMetrics | null;
  lineItemPerformanceData: PlatformBuyMetric[];
  onChartGranularityToggle: (granularity: Granularity) => void;
  onLinkPlatformBuyClick: (plan: CombinedMediaPlan) => void;
}) {
  const [displayMode, setDisplayMode] = useState<DisplayMode>('discrete');
  const [chartType, setChartType] = useState<ChartType>('spend');
  const mediaPlanMetrics = useMemo(
    () =>
      lineItem.media_plans
        .map(mediaPlan => calcMediaPlanMetrics(lineItemPerformanceData, lineItem, mediaPlan))
        .filter(metrics => metrics != null),
    [lineItemPerformanceData, lineItem]
  );
  const [colorMap, setColorMap] = useState<Record<string, string>>({});

  return (
    <>
      {lineItemMetrics && <MediaBuyHeroMetrics metrics={lineItemMetrics} />}
      <div className="flex flex-grow flex-col">
        <div className="flex items-center">
          <ChartTypeSelector selected={chartType} setSelected={setChartType} />
          <GranularitySelector
            granularity={granularity}
            setGranularity={onChartGranularityToggle}
          />
          <div className="flex-1" />
          <DisplayModeSelector displayMode={displayMode} setDisplayMode={setDisplayMode} />
        </div>
        <MediaBuyGraph
          data={lineItemPerformanceData}
          lineItem={lineItem}
          mediaPlans={lineItem.media_plans}
          granularity={granularity}
          displayMode={displayMode}
          chartType={chartType}
          setColorMap={setColorMap}
        />
      </div>
      <div className="flex shrink flex-col gap-2">
        <div className="flex h-[50px] items-center justify-between">
          <div className="item-center flex gap-4">
            <div className="text-lg font-bold">Media Plans</div>
            <div className="rounded bg-blue-100 px-2 py-0.5 font-semibold text-gray-600">
              {lineItem.media_plans.length}
            </div>
          </div>
          {createButton}
        </div>
        <MediaPlansTable
          lineItem={lineItem}
          metrics={mediaPlanMetrics}
          colorMap={colorMap}
          onLinkPlatformBuyClick={onLinkPlatformBuyClick}
          lineItemMetrics={lineItemMetrics}
        />
      </div>
    </>
  );
}

function NoPlatformBuysView({ searchButton }: { searchButton: ReactNode }) {
  return (
    <div className="flex h-full items-center justify-around">
      <div className="flex flex-col items-center gap-4">
        <NoPlatformBuysImage />
        <div className="flex flex-col items-center">
          <div className="mb-6 flex flex-col items-center gap-2 text-gray-600">
            <div className="flex flex-col items-center text-lg">
              <div className="font-semibold">No Media Plans yet.</div>
              <div>Search and add your Platform Buys to create Media Plans.</div>
            </div>
            <div className="text-center text-sm">
              Search for Platform Buys you’ve already created on the DSP. <br />
              Add them all at once. Then define their Media Plan.
            </div>
          </div>
          {searchButton}
        </div>
        <div className="flex rounded bg-gray-200">
          <div className="flex items-center px-1">
            <FontAwesomeIcon icon={faInfoCircle} />
          </div>
          <div className="rounded bg-gray-100 px-2 py-1 text-sm text-gray-800">
            Newly created Platform Buys are available in Bravo the following morning
          </div>
        </div>
      </div>
    </div>
  );
}

function NoMediaPlatformsView() {
  return (
    <div className="flex h-full items-center justify-around">
      <div className="flex flex-col items-center gap-4">
        <NoMediaPlatformsImage />
        <div className="flex flex-col items-center gap-2 text-gray-600">
          <div className="flex flex-col items-center text-lg font-semibold">
            No Platforms have been assigned for this line item
          </div>
          <div className="text-center text-sm">
            Platform must be assigned to a line item before Media Plans can be created. <br />
            Reach out to your Sr. Media Trader to get platforms assigned.
          </div>
        </div>
        <div className="flex rounded bg-red-100">
          <div className="flex items-center px-1 text-red-600">
            <FontAwesomeIcon icon={faHand} />
          </div>
          <div className="rounded bg-red-200 px-2 py-1 text-sm text-gray-800">
            No Platform assigned
          </div>
        </div>
      </div>
    </div>
  );
}
