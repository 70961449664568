import React, { useCallback, useEffect, useMemo, useState } from 'react';
import '@ag-grid-community/styles/ag-grid.css';
import '@ag-grid-community/styles/ag-theme-quartz.css';
import { groupBy } from 'lodash';
import {
  PlatformBuy,
  PlatformBuySearchMediaPlans,
  PlatformBuySearchRecord
} from '../../../../shared/src/media-buy-types';
import { getPlatformEntityType, getPlatformName } from '../../../../shared/src/media-platforms';
import { AgGridReact, CustomCellRendererProps, CustomTooltipProps } from '@ag-grid-community/react';
import {
  ColDef,
  GridApi,
  GridReadyEvent,
  IServerSideDatasource,
  ModuleRegistry,
  RowClassParams
} from '@ag-grid-community/core';
import { defaultColDef } from '../../components/table-utils';
import { ServerSideRowModelModule } from '@ag-grid-enterprise/server-side-row-model';
import { RowGroupingModule } from '@ag-grid-enterprise/row-grouping';
import Modal, { Props as ModalProps } from '../../components/modal';
import { SearchBar } from '../../components/search-bar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCalendarDays,
  faCircleCheck,
  faInfoCircle,
  faLayerGroup,
  faMagnifyingGlassPlus,
  faPlusCircle,
  faQuestionCircle,
  faTriangleExclamation
} from '@fortawesome/pro-light-svg-icons';
import { Button, SecondaryButton } from '../../components/button';
import { Platform, TacticSelection } from '../../../../shared/src/line-item-channels';
import { CreateMediaPlanOptions, DraftStrategyChanges } from '../../../../shared/src/types';
import { CombinedLineItem, CombinedMediaPlan } from '../../store/strategy-combiner';
import { areRequiredFieldsSet } from '../line-items/line-item-errors';
import { client } from '../../utils/trpc-client';
import { PlatformBuyStatus } from './platform-buy-status';
import { Tooltip } from 'react-tooltip';
import { Link } from 'react-router-dom';
import { useStore } from '../../store/store';
import { useStrategyContext } from '../line-items/strategy-context';
import { linkPlatformBuy } from './media-plans-update-helper';
import { v4 as uuid } from 'uuid';
import { useCaptureMediaPlanCreate } from '../../utils/posthog/analytics';

ModuleRegistry.registerModules([ServerSideRowModelModule, RowGroupingModule]);

type Props = {
  lineItem: CombinedLineItem;
  onClick: () => void;
};
export function PlatformBuySearchButton({ lineItem, onClick }: Props) {
  const lineItemHasMediaPlatforms = lineItem.media_platforms && lineItem.media_platforms.length > 0;
  const disabled = !isLineItemReady(lineItem) || !lineItemHasMediaPlatforms;

  return (
    <>
      <Button
        data-tooltip-id="no-media-platforms"
        disabled={disabled}
        onClick={onClick}
        className="gap-2">
        <FontAwesomeIcon icon={faMagnifyingGlassPlus} />
        {lineItem.media_plans.length > 0 ? 'Add Media Plan' : 'Create Media Plans'}
      </Button>
      {!lineItemHasMediaPlatforms && (
        <Tooltip id="no-media-platforms" place="bottom" style={{ width: 270, zIndex: 100000 }}>
          <div className="text-center text-sm">
            <div className="font-semibold">No assigned platforms</div>
            Platform must be assigned before Media Plans can be created. <br />
            Reach out to your Sr. Media Trader to get platforms assigned.
          </div>
        </Tooltip>
      )}
    </>
  );
}

type LocallyAddedBuy = Omit<PlatformBuySearchRecord, 'availability'>;

type PlatformBuysSearchModalProps = {
  open: boolean;
  setOpen: (open: boolean) => void;
  lineItem: CombinedLineItem;
  planToLink: CombinedMediaPlan | null;
};
export function PlatformBuysSearchModal({
  lineItem,
  planToLink,
  open,
  setOpen
}: PlatformBuysSearchModalProps) {
  const { strategy } = useStrategyContext();
  const addMediaPlan = useStore(state => state.addMediaPlan);
  const defaultQuery = useMemo(
    () =>
      lineItem.media_platforms
        ? getDefaultQuery(strategy.campaign.campaign_number, lineItem.media_platforms)
        : '',
    [strategy.campaign.campaign_number, lineItem.media_platforms]
  );
  const [searchText, setSearchText] = useState<string>(defaultQuery);
  const pendingMediaPlans = useMemo(
    () => getPendingMediaPlansWithPlatformBuys(strategy.changes.media_plans),
    [strategy]
  );
  const [addedBuys, setAddedBuys] = useState<LocallyAddedBuy[]>([]);
  const captureCreateEvent = useCaptureMediaPlanCreate();

  function handleAddMediaPlan(options: CreateMediaPlanOptions) {
    addMediaPlan(options);
    captureCreateEvent(options.id, options.media_buy?.id, lineItem, strategy.campaign);
  }

  useEffect(() => {
    setSearchText(defaultQuery);
  }, [defaultQuery, open]);

  const handleClose = () => {
    addedBuys.forEach(buy => handleAddMediaPlan(createDraftMediaPlan(lineItem, buy))); // TODO bulk add?
    setAddedBuys([]);
  };

  const handleAdd = (buy: LocallyAddedBuy) => {
    if (planToLink) {
      linkPlatformBuy(planToLink, createDraftPlatformBuy(buy), lineItem);
      setOpen(false);
    } else {
      setAddedBuys([...addedBuys, buy]);
    }
  };

  return (
    <Modal
      open={open}
      setOpen={setOpen}
      onClose={handleClose}
      header={
        <ModalHeader
          defaultQuery={defaultQuery}
          onSubmitSearch={setSearchText}
          planToLink={planToLink}
        />
      }
      content={
        <PlatformBuysTable
          lineItem={lineItem}
          locallyAddedBuys={addedBuys}
          onPlatformBuyAdd={handleAdd}
          pendingMediaPlans={pendingMediaPlans}
          searchText={searchText}
        />
      }
      footer={<ModalFooter setOpen={setOpen} onClose={handleClose} />}
      width="wide"
    />
  );
}

function ModalHeader({
  defaultQuery,
  onSubmitSearch,
  planToLink
}: {
  defaultQuery: string;
  onSubmitSearch: (text: string) => void;
  planToLink: CombinedMediaPlan | null;
}) {
  return (
    <div className="flex justify-between gap-3 px-4 pb-2 pt-4">
      <div className="shrink-0 basis-52 text-lg font-semibold">
        {planToLink
          ? 'Select the Platform Buy for this Media Plan'
          : 'Select Platform Buys for this Line Item'}
      </div>
      <div className="grow rounded-md bg-blue-50 p-1">
        <SearchBar defaultQuery={defaultQuery} submitSearch={onSubmitSearch} />
        <div className="mt-1 flex items-center justify-between text-xs">
          <div className="flex items-start gap-1">
            <span className="shrink-0 font-semibold">Default query:</span>
            <span className="shrink">{defaultQuery}</span>
          </div>
          <div className="flex shrink-0 items-center justify-end gap-1 text-blue-600">
            <FontAwesomeIcon data-tooltip-id="query-help" icon={faQuestionCircle} />
            <Tooltip
              id="query-help"
              opacity={1}
              place="left"
              style={{ backgroundColor: '#111827', borderRadius: '4px', zIndex: 100000 }}>
              <div className="text-sm">
                <div className="font-semibold">Search Tips</div>
                <ul className="list-disc pl-4">
                  <li>Wrap names and phrases with spaces in “ ”.</li>
                  <li>
                    Different criteria must be separated with AND or OR.
                    <ul className="list-disc pl-4">
                      <li>AND narrows a search (ex. Viant AND Awareness)</li>
                      <li>OR broadens a search. (ex. Viant AND Awareness OR Traffic)</li>
                    </ul>
                  </li>
                </ul>
              </div>
            </Tooltip>
          </div>
        </div>
      </div>
    </div>
  );
}

type PlatformBuysTableData = PlatformBuySearchRecord & {
  button: '';
};

type BottomRowData = {
  name: { count: number; label: string; message?: string; isError?: boolean };
  media_platform_id: { count: number; label: string };
  button: '';
};

function PlatformBuysTable({
  lineItem,
  locallyAddedBuys,
  pendingMediaPlans,
  onPlatformBuyAdd,
  searchText
}: {
  lineItem: CombinedLineItem;
  locallyAddedBuys: LocallyAddedBuy[];
  onPlatformBuyAdd: (buy: LocallyAddedBuy) => void;
  pendingMediaPlans: PlatformBuySearchMediaPlans[];
  searchText: string;
}) {
  const [gridApi, setGridApi] = useState<GridApi<PlatformBuysTableData> | null>(null);
  const [bottomRowData, setBottomRowData] = useState<BottomRowData>(getBottomRowData(0, 0));
  const columnDefs = useMemo<ColDef<PlatformBuysTableData>[]>(
    () => getColumnDefs(lineItem, onPlatformBuyAdd, locallyAddedBuys),
    [lineItem, onPlatformBuyAdd, locallyAddedBuys]
  );

  useEffect(() => {
    if (gridApi) {
      gridApi.setGridOption(
        'serverSideDatasource',
        getServerSideDatasource(setBottomRowData, lineItem.id, searchText, pendingMediaPlans)
      );
    }
  }, [gridApi, lineItem.id, searchText, pendingMediaPlans]);

  const onGridReady = useCallback((params: GridReadyEvent) => {
    setGridApi(params.api);
  }, []);

  const getRowStyle = useCallback(
    (params: RowClassParams<PlatformBuysTableData>) => {
      const { data, node } = params;

      if (!data) return undefined;

      if (node.rowPinned === 'bottom') {
        return {
          backgroundColor: '#f3f4f6' // bg-gray-100
        };
      }

      const { availability, id } = data;

      if (availability.status === 'added' || isAddedToPlan(id, locallyAddedBuys)) {
        return {
          backgroundColor: '#eff6ff' // bg-blue-50
        };
      }

      if (availability.status !== 'available') {
        return {
          backgroundColor: '#f9fafb' // bg-gray-50
        };
      }

      return undefined;
    },
    [locallyAddedBuys]
  );

  return (
    <div className="ag-theme-quartz px-4" style={{ height: '100%', width: '100%' }}>
      <AgGridReact
        autoSizeStrategy={{ type: 'fitGridWidth' }}
        cacheBlockSize={50}
        cacheOverflowSize={2}
        columnDefs={columnDefs}
        defaultColDef={defaultColDef}
        infiniteInitialRowCount={100}
        maxBlocksInCache={10}
        onGridReady={onGridReady}
        pinnedBottomRowData={[bottomRowData]}
        rowBuffer={0}
        rowModelType="serverSide"
        getRowStyle={getRowStyle}
        suppressCellFocus
        suppressRowHoverHighlight
        tooltipShowDelay={500}
      />
    </div>
  );
}

function ModalFooter({ onClose, setOpen }: Pick<ModalProps, 'setOpen'> & { onClose: () => void }) {
  return (
    <div className="flex justify-between p-4">
      <div className="flex rounded bg-gray-200">
        <div className="flex items-center px-1">
          <FontAwesomeIcon icon={faInfoCircle} />
        </div>
        <div className="rounded bg-gray-100 p-2 text-sm text-gray-800">
          Newly created platform buys are available in Bravo the following morning
        </div>
      </div>
      <SecondaryButton
        onClick={() => {
          onClose();
          setOpen(false);
        }}>
        Done
      </SecondaryButton>
    </div>
  );
}

function getColumnDefs(
  lineItem: CombinedLineItem,
  onPlatformBuyAdd: (buy: LocallyAddedBuy) => void,
  locallyAddedBuys: LocallyAddedBuy[]
): ColDef<PlatformBuysTableData>[] {
  return [
    {
      cellRenderer: (props: CustomCellRendererProps<PlatformBuysTableData>) => {
        const { data, node, value } = props;

        if (!data) return null;

        if (node.rowPinned === 'bottom') {
          return (
            <div className="flex gap-1">
              {value.isError ? (
                value.message
              ) : (
                <>
                  <span className="font-semibold">{Number(value.count).toLocaleString()}</span>
                  {value.label}
                  {value.message ? ` : ${value.message}` : ''}
                </>
              )}
            </div>
          );
        }

        return value;
      },
      cellStyle: { border: 'none', overflow: 'scroll', textOverflow: 'initial' },
      field: 'name',
      headerName: 'Platform Buy',
      maxWidth: 800,
      sortable: false,
      resizable: false,
      suppressMovable: true
    },
    {
      cellRenderer: (props: CustomCellRendererProps<PlatformBuysTableData>) => {
        const { data, node, value } = props;

        if (!data) return null;

        if (node.rowPinned === 'bottom') {
          return (
            <div className="flex gap-1">
              <span className="font-semibold">{Number(value.count).toLocaleString()}</span>
              {value.label}
            </div>
          );
        }

        return value;
      },
      cellStyle: { border: 'none', overflow: 'scroll', textOverflow: 'initial' },
      field: 'media_platform_id',
      headerName: 'Platform Entity',
      pinned: 'right',
      resizable: false,
      sortable: false,
      suppressMovable: true,
      valueGetter: params => {
        const { data, node } = params;

        if (!data) return '';

        if (node?.rowPinned === 'bottom') {
          return data.media_platform_id;
        }

        return `${getPlatformName(data.media_platform_id)} ${getPlatformEntityType(data.media_platform_id)}`;
      },
      maxWidth: 175
    },
    {
      cellRenderer: (props: CustomCellRendererProps<PlatformBuysTableData>) => {
        const { data, node } = props;

        if (!data) return null;

        if (node.rowPinned === 'bottom') {
          return <div className="h-full w-full bg-gray-100" />;
        }

        const { button, availability, ...searchRecord } = data;

        if (availability.status === 'added' || isAddedToPlan(searchRecord.id, locallyAddedBuys)) {
          return (
            <div className="inline-flex h-full w-full items-center pl-1 ">
              <PlatformBuyStatus icon={faCircleCheck} message="Added" theme="blue" />
            </div>
          );
        }

        return (
          <div className="inline-flex h-full w-full items-center pl-1">
            {getAvailabilityCell(availability, () => {
              onPlatformBuyAdd(searchRecord);
            })}
          </div>
        );
      },
      cellStyle: {
        display: 'flex',
        alignItems: 'center',
        padding: 0,
        border: 'none'
      },
      field: 'button',
      headerName: '',
      maxWidth: 180,
      pinned: 'right',
      resizable: false,
      sortable: false,
      suppressMovable: true,
      tooltipComponent: (props: CustomTooltipProps & { tactic: TacticSelection }) => (
        <div className="rounded bg-slate-800 px-4 py-1.5 text-sm text-white">{props.value}</div>
      ),
      tooltipValueGetter: params => {
        const { data, node } = params;

        if (!data || node?.rowPinned === 'bottom') return null;

        const { availability } = data;

        switch (availability.status) {
          case 'added':
            return null;
          case 'available':
            return null;
          case 'incompatible_platform':
            return (
              <>
                <span className="font-semibold">{getPlatformName(data.media_platform_id)}</span>{' '}
                isn’t a platform option for this line item’s tactic:{' '}
                <span className="font-semibold">{lineItem.tactic?.name}</span>
              </>
            );
          case 'invalid_dates':
            return 'This platform buy’s dates don’t overlap the line item’s dates';
          case 'unavailable':
            return 'This platform buy’s overlapping dates are already linked to another line item';
          case 'unavailable_platform':
            return (
              <>
                <span className="font-semibold">{getPlatformName(data.media_platform_id)}</span>{' '}
                isn’t a platform assigned to this line item by the Sr. Media Trader
              </>
            );
        }
      }
    }
  ];
}

function getAvailabilityCell(
  availability: PlatformBuySearchRecord['availability'],
  onAddClick: () => void
) {
  switch (availability.status) {
    case 'incompatible_platform':
      return <PlatformBuyStatus icon={faLayerGroup} theme="red" message="Invalid platform" />;
    case 'unavailable_platform':
      return (
        <PlatformBuyStatus icon={faLayerGroup} theme="yellow" message="Not assigned platform" />
      );
    case 'invalid_dates':
      return <PlatformBuyStatus icon={faCalendarDays} theme="red" message="Invalid dates" />;
    case 'unavailable':
      return (
        <PlatformBuyStatus
          icon={faTriangleExclamation}
          theme="yellow"
          message={
            <Link
              className="underline"
              target="_blank"
              to={`/campaigns/${availability.assigned_campaign_id}/strategy/lineitems/${availability.assigned_line_item_id}/overview`}>
              Dates allocated
            </Link>
          }
        />
      );
    case 'available':
      return (
        <SecondaryButton onClick={onAddClick} className="mr-4 w-full" size="fitGridCell">
          <div className="flex items-center gap-2">
            <FontAwesomeIcon icon={faPlusCircle} />
            Add
          </div>
        </SecondaryButton>
      );
    case 'added':
      return <PlatformBuyStatus icon={faCircleCheck} message="Added" theme="blue" />;
  }
}

function getServerSideDatasource(
  setBottomRowData: (data: BottomRowData) => void,
  lineItemId: string,
  searchText: string,
  pendingMediaPlans: PlatformBuySearchMediaPlans[]
): IServerSideDatasource {
  return {
    getRows: async params => {
      if (searchText === '') {
        setBottomRowData(getBottomRowData(0, 0, 'Enter a search query and search again'));
        params.success({ rowData: [] });
        return;
      }

      try {
        const { media_buys, platform_count, total } = await client.searchPlatformBuys.query({
          line_item_id: lineItemId,
          pending_media_plans: pendingMediaPlans,
          page: params.request.endRow ? Math.ceil(params.request.endRow / 50) : 1,
          search: searchText
        });

        if (total == 0) {
          setBottomRowData(
            getBottomRowData(0, 0, 'No matching platform buys found, try updating your query')
          );
          params.success({ rowData: [] });
          return;
        }

        setBottomRowData(getBottomRowData(total, platform_count));
        params.success({ rowData: media_buys });
      } catch (error) {
        console.error('Failed to fetch platform buys', error);
        setBottomRowData(
          getBottomRowData(0, 0, 'Error in query syntax - fix your query and try again', true)
        );
        params.success({ rowData: [] });
        return;
      }
    }
  };
}

function getDefaultQuery(campaignNumber: string, platforms: Platform[]): string {
  const formattedCampaignNumber = campaignNumber.replace(/\s/g, '');

  if (platforms.length === 0) {
    return formattedCampaignNumber;
  }

  const formattedPlatformNames = platforms.map(({ name }) =>
    name.includes(' ') ? `"${name}"` : name
  );

  if (platforms.length === 1) {
    return `${formattedCampaignNumber} AND ${formattedPlatformNames[0]}`;
  }

  return `${formattedCampaignNumber} AND (${formattedPlatformNames.join(' OR ')})`;
}

function getBottomRowData(
  nameCount: number,
  platformCount: number,
  message?: string,
  isError?: boolean
): BottomRowData {
  return {
    name: {
      count: nameCount,
      label: nameCount == 1 ? 'Result' : 'Results',
      message,
      isError
    },
    media_platform_id: {
      count: platformCount,
      label: platformCount == 1 ? 'Platform' : 'Platforms'
    },
    button: ''
  };
}

function isLineItemReady(lineItem: CombinedLineItem): boolean {
  return areRequiredFieldsSet(lineItem);
}

function isAddedToPlan(buyId: string, addedBuys: LocallyAddedBuy[]) {
  return addedBuys.some(buy => buy.id === buyId);
}

function createDraftMediaPlan(
  lineItem: CombinedLineItem,
  buy: LocallyAddedBuy
): CreateMediaPlanOptions {
  return {
    id: uuid(),
    strategy_id: lineItem.strategy_id,
    line_item_id: lineItem.id,
    media_buy: createDraftPlatformBuy(buy),
    name: '',
    budget: 1,
    target_unit_cost: lineItem.unit_price_type?.name === 'CPM' ? 0.001 : 1,
    is_deleted: false
  };
}

function createDraftPlatformBuy(buy: LocallyAddedBuy): PlatformBuy {
  // TODO budget and media_platform_entity are being mocked in for now until we can think about how to properly get the info or not require them in the MediaPlans in the table
  return {
    ...buy,
    budget: 0,
    media_platform_entity: {
      id: '',
      media_platform_id: 0,
      entity_id: '',
      entity_type: '',
      entity_name: '',
      raw_entity: undefined
    }
  };
}

function getPendingMediaPlansWithPlatformBuys(
  changes: DraftStrategyChanges['media_plans']
): PlatformBuySearchMediaPlans[] {
  const changesByLineItemId = groupBy(Object.values(changes), 'data.line_item_id');

  return Object.entries(changesByLineItemId)
    .map(([lineItemId, changes]) => ({
      line_item_id: lineItemId,
      platform_buy_ids: changes
        .map(change => change.data.media_buy?.id)
        .filter(id => id !== undefined)
    }))
    .filter(changes => changes.platform_buy_ids.length > 0);
}
