import React from 'react';
import { getPerformanceBackground, getPerformanceColor } from '../../../utils/color-utils';
import { PendingPropertyIndicator, PropertyIndicatorContainer } from './utils';
import { PlatformBuyIcon } from '../../nav-icons';
import { formatMetricPercentage } from '../../hero-metrics';

type Props = {
  value: number;
};

export function PacingIndicator({ value }: Props) {
  if (value === 0) {
    return (
      <PendingPropertyIndicator
        label={
          <div className="flex items-center gap-2">
            Pending
            <PlatformBuyIcon />
          </div>
        }
      />
    );
  }

  const background = getPerformanceBackground(value);
  const color = getPerformanceColor(value);

  return (
    <PropertyIndicatorContainer style={{ background, color }}>
      <div className="text-center text-sm font-bold">{formatMetricPercentage(value)}</div>
    </PropertyIndicatorContainer>
  );
}
