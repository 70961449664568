import React from 'react';

export function CaretDecrease({ sentiment }: { sentiment: 'positive' | 'negative' }) {
  return (
    <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1.49035 4.49173L8.00894 11.9916L14.509 4.475L1.49035 4.49173Z"
        fill={sentiment === 'positive' ? '#65A30D' : '#B91C1C'}
      />
    </svg>
  );
}
