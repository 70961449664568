import { format } from '@visx/vendor/d3-format';
import { PartialLineItem } from 'shared/src/line-item-types';
import { UnitPriceType } from './line-item-channels';

export const formatNumber = format('.3~s');
export const formatPercentage = format(',.1%');
export const formatPercentageNoDecimal = format('.0%');
export const formatSpend = format('$,.2f');

export function calcEstimatedUnits(lineItem: PartialLineItem) {
  const { price, unit_price } = lineItem;
  return price && unit_price ? Math.round(price / unit_price) : undefined;
}

export function calcAdjustedUnitCost(lineItem: PartialLineItem) {
  const { unit_price, target_margin } = lineItem;
  if (unit_price == null || target_margin == null) return undefined;
  return lineItem.unit_price_type?.name === 'CPM'
    ? unit_price * 1000 * (1 - target_margin)
    : unit_price * (1 - target_margin);
}

export function getAdjustedUnitPrice(lineItem: PartialLineItem) {
  const { unit_price } = lineItem;
  if (unit_price == null) return undefined;
  return lineItem.unit_price_type?.name === 'CPM' ? unit_price * 1000 : unit_price;
}

export function calcUnitCost(lineItem: PartialLineItem) {
  const { unit_price, target_margin } = lineItem;
  if (unit_price == null || target_margin == null) return undefined;
  return unit_price * (1 - target_margin);
}

export function calcMediaBudget(lineItem: PartialLineItem) {
  const estimatedUnits = calcEstimatedUnits(lineItem);
  const unitCost = calcUnitCost(lineItem);
  return estimatedUnits && unitCost ? estimatedUnits * unitCost : undefined;
}

export function calcPacingBlockBudget(lineItem: PartialLineItem, price: number) {
  const { unit_price, target_margin } = lineItem;
  if (unit_price == null || unit_price === 0 || target_margin == null) return undefined;
  const estimatedUnits = Math.round(price / unit_price);
  const unitCost = unit_price * (1 - target_margin);
  return estimatedUnits * unitCost;
}

export function getUnitName(unit: UnitPriceType['name']): { full: string; abbr: string } {
  switch (unit) {
    case 'CPM':
      return { full: 'impressions', abbr: 'impr.' };
    case 'CPC':
      return { full: 'clicks', abbr: 'clicks' };
    case 'CPV':
      return { full: 'views', abbr: 'views' };
    case 'CPCV':
      return { full: 'completed views', abbr: 'comp. views' };
    case 'CPA':
      return { full: 'actions', abbr: 'actions' };
    case 'CPL':
      return { full: 'leads', abbr: 'leads' };
    case 'Flat Fee':
      return { full: '', abbr: '' };
  }
}

export function getElapsedDaysDisplay(elapsedDays: number, lengthInDays: number) {
  if (elapsedDays < 0) return 0;
  if (elapsedDays > lengthInDays) return lengthInDays;
  return elapsedDays;
}
