import { z } from 'zod';
import { SortDirection } from 'shared/src/media-buy-types';
import { AgDateFilter, AgNumberFilter, AgSetFilter } from 'shared/src/ag-grid-filter-types';
import { ZohoCampaignStatus } from 'shared/src/zoho-types';
import { TeamUser } from 'shared/src/user-types';
import { dateToUTCOrNull } from 'shared/src/zod-utils';

export const Campaign = z.object({
  campaign_number: z.string(),
  campaign_id: z.string(),
  name: z.string(),
  stage: ZohoCampaignStatus,
  budget: z.number().nullable(),
  account: z.object({ id: z.string(), name: z.string() }),
  agency: z.object({ id: z.string(), name: z.string() }).nullable(),
  team: z.array(TeamUser).nullable(),
  team_name: z.string().nullable(),
  created_time: dateToUTCOrNull.nullable(),
  flight_date: dateToUTCOrNull.nullable(),
  end_date: dateToUTCOrNull.nullable(),
  goals_kpis: z.string().nullable(),
  lead_account_owner: z.string().nullable(),
  new_business: z.boolean().nullable()
});
export type Campaign = z.infer<typeof Campaign>;

export const CampaignPlans = z.object({
  line_item_price_allocated: z.number(),
  line_items_count: z.number(),
  line_items_with_platforms_count: z.number(),
  line_items_with_media_plans_count: z.number(),
  total_media_budget_allocated: z.number(),
  total_units_allocated: z.number(),
  total_media_plans_count: z.number(),
  total_media_plans_linked: z.number()
});
export type CampaignPlans = z.infer<typeof CampaignPlans>;

export const CampaignListRow = Campaign.merge(CampaignPlans);
export type CampaignListRow = z.infer<typeof CampaignListRow>;

export const CampaignSortColumn = z.union([
  z.literal('name'),
  z.literal('campaign_number'),
  z.literal('stage'),
  z.literal('budget'),
  z.literal('account'),
  z.literal('line_item_price_allocated'),
  z.literal('line_items_count'),
  z.literal('line_items_with_platforms_count'),
  z.literal('line_items_with_media_plans_count'),
  z.literal('total_media_budget_allocated'),
  z.literal('total_units_allocated'),
  z.literal('total_media_plans_count'),
  z.literal('total_media_plans_linked')
]);
export type CampaignSortColumn = z.infer<typeof CampaignSortColumn>;
export const campaignSortColumns = CampaignSortColumn.options.map(option => option.value);

export const CampaignSort = z.object({
  colId: CampaignSortColumn,
  sort: SortDirection
});
export type CampaignSort = z.infer<typeof CampaignSort>;

export const CampaignFilterTypes = z.object({
  stage: AgSetFilter.optional(),
  budget: AgNumberFilter.optional(),
  flight_date: AgDateFilter.optional(),
  end_date: AgDateFilter.optional(),
  line_item_price_allocated: AgNumberFilter.optional(),
  line_items_count: AgNumberFilter.optional(),
  line_items_with_platforms_count: AgNumberFilter.optional(),
  line_items_with_media_plans_count: AgNumberFilter.optional(),
  total_media_budget_allocated: AgNumberFilter.optional(),
  total_units_allocated: AgNumberFilter.optional(),
  total_media_plans_count: AgNumberFilter.optional(),
  total_media_plans_linked: AgNumberFilter.optional()
});
export type CampaignFilterTypes = z.infer<typeof CampaignFilterTypes>;

export const CampaignListRequest = z.object({
  page: z.number(),
  search: z.string().optional(),
  sort: CampaignSort.optional(),
  filters: CampaignFilterTypes
});

export type CampaignListRequest = z.infer<typeof CampaignListRequest>;

export const CampaignListResult = z.object({
  total: z.number(),
  campaigns: z.array(CampaignListRow)
});

export type CampaignListResult = z.infer<typeof CampaignListResult>;

export const CampaignListUrlParams = CampaignListRequest.omit({ page: true, search: true });
export type CampaignListUrlParams = z.infer<typeof CampaignListUrlParams>;
