import React from 'react';

export function MinusInCircle() {
  return (
    <svg height="14" width="14" fill="none" viewBox="0 0 14 14" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_10051_624588)">
        <path
          d="M9.23135 6.49683H4.76885C4.50635 6.49683 4.2876 6.71558 4.2876 6.99995C4.2876 7.26245 4.50635 7.4812 4.79072 7.4812H9.25322C9.51572 7.4812 9.73447 7.26245 9.73447 6.97808C9.7126 6.71558 9.49385 6.49683 9.23135 6.49683Z"
          fill="#9CA3AF"
        />
        <path
          d="M7.00005 0.393799C3.34692 0.393799 0.393799 3.34692 0.393799 7.00005C0.393799 10.6532 3.3688 13.6282 7.02192 13.6282C10.675 13.6282 13.65 10.6532 13.65 7.00005C13.6282 3.34692 10.6532 0.393799 7.00005 0.393799ZM7.00005 12.6438C3.8938 12.6438 1.37817 10.1063 1.37817 7.00005C1.37817 3.8938 3.8938 1.37817 7.00005 1.37817C10.1063 1.37817 12.6438 3.91567 12.6438 7.02192C12.6438 10.1063 10.1063 12.6438 7.00005 12.6438Z"
          fill="#9CA3AF"
        />
      </g>
      <defs>
        <clipPath id="clip0_10051_624588">
          <rect height="14" width="14" fill="white" rx="6" />
        </clipPath>
      </defs>
    </svg>
  );
}
