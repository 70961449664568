import React, { useState } from 'react';
import { CombinedLineItem, CombinedMediaPlan } from '../../store/strategy-combiner';
import { faCode, faGaugeHigh, faList } from '@fortawesome/pro-light-svg-icons';
import ReactJsonView from '@microlink/react-json-view';
import { ExpandableSidebarSection, PageContentSection, PageSection } from '../../components/layout';
import { MediaBuyGraph } from './media-buy-graph';
import { useMediaBuyMetrics } from './use-media-buy-metrics';
import { MediaBuyHeroMetrics } from './media-buy-hero-metrics';
import { calcMediaPlanMetrics } from '../../metrics/calculate-metrics';
import {
  ChartType,
  ChartTypeSelector,
  Granularity,
  GranularitySelector
} from '../../components/charts/chart-type-selector';
import { DisplayMode, DisplayModeSelector } from '../../components/charts/display-mode-selector';
import { PerformanceRail } from '../shared/performance-rail';
import { convertToLineItem, isCompleted } from 'shared/src/line-item-types';
import { DetailsRail } from '../shared/details-rail';
import { mediaBuyTitle, PageTitle } from '../shared/page-title';

type Props = {
  lineItem: CombinedLineItem;
  mediaPlan: CombinedMediaPlan;
};

export function MediaBuyOverview({ lineItem, mediaPlan }: Props) {
  const [granularity, setGranularity] = useState<Granularity>('day');
  const [displayMode, setDisplayMode] = useState<DisplayMode>('discrete');
  const [chartType, setChartType] = useState<ChartType>('spend');
  const { data } = useMediaBuyMetrics(mediaPlan.media_buy?.id, granularity);
  const metrics = calcMediaPlanMetrics(data, lineItem, mediaPlan);

  const completeLineItem = isCompleted(lineItem) ? convertToLineItem(lineItem) : null;

  return (
    <PageSection>
      <PageTitle title={mediaBuyTitle(mediaPlan.name || 'Unnamed')} />
      <PageContentSection>
        {metrics && <MediaBuyHeroMetrics metrics={metrics} />}
        <div className="flex items-center py-2">
          <ChartTypeSelector selected={chartType} setSelected={setChartType} />
          <GranularitySelector granularity={granularity} setGranularity={setGranularity} />
          <div className="flex-1" />
          <DisplayModeSelector displayMode={displayMode} setDisplayMode={setDisplayMode} />
        </div>
        <MediaBuyGraph
          data={data}
          lineItem={lineItem}
          mediaPlans={[mediaPlan]}
          granularity={granularity}
          displayMode={displayMode}
          chartType={chartType}
        />
      </PageContentSection>
      <ExpandableSidebarSection
        sections={[
          ...(completeLineItem && metrics
            ? [
                {
                  name: 'details',
                  header: 'Platform Buy Details',
                  icon: faList,
                  render: (setExpanded: (expanded: string) => void) => (
                    <DetailsRail
                      lineItem={completeLineItem}
                      metrics={metrics}
                      setExpanded={setExpanded}
                    />
                  )
                },
                {
                  name: 'performance',
                  header: 'Platform Buy Performance',
                  icon: faGaugeHigh,
                  render: () => <PerformanceRail lineItem={completeLineItem} metrics={metrics} />
                }
              ]
            : []),
          {
            name: 'config',
            header: 'Config',
            icon: faCode,
            render: () => <RawConfigSection mediaPlan={mediaPlan} />
          }
        ]}
      />
    </PageSection>
  );
}

function RawConfigSection({ mediaPlan }: { mediaPlan: CombinedMediaPlan | undefined }) {
  // TODO[mk] - MEDIA_PLANS - show media buy raw entity here if available
  return (
    <div className="flex h-full flex-col">
      <ReactJsonView src={mediaPlan?.media_buy?.media_platform_entity?.raw_entity || {}} />
    </div>
  );
}
