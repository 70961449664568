import React from 'react';
import clsx from 'clsx';
import { CheckInCircle } from '../../../icons/check-in-circle';
import { Circle } from '../../../icons/circle';
import { MinusInCircle } from '../../../icons/minus-in-circle';
import { MediaPlanIcon } from '../../nav-icons';
import { calcProportion } from './utils';
import { LineItemPerformanceListRow } from '../../../../../shared/src/line-item-types';

type PlanStatus = 'complete' | 'incomplete' | 'pending';
type Props = {
  status: PlanStatus;
  entityIcon: React.ReactNode;
};

export function PlanStatusIndicator({ status, entityIcon }: Props) {
  const bgColor = calcBgColor(status);
  const icon = calcIcon(status);

  return (
    <div className="w-full rounded-lg bg-gray-100 p-1">
      <div
        className={clsx(
          'flex grow cursor-pointer items-center justify-start gap-1 rounded bg-gradient-to-r p-1.5',
          bgColor
        )}>
        {icon}
        {entityIcon}
      </div>
    </div>
  );
}

function calcBgColor(status: PlanStatus) {
  switch (status) {
    case 'complete':
      return 'from-[#BEF264] to-[#A3E635]';
    case 'incomplete':
      return 'from-[#FDE047] to-[#FACC15]';
    case 'pending':
      return 'from-[#F3F4F6] to-[#EFEFEF]';
  }
}

function calcIcon(status: PlanStatus) {
  switch (status) {
    case 'complete':
      return <CheckInCircle />;
    case 'incomplete':
      return <Circle />;
    case 'pending':
      return <MinusInCircle />;
  }
}

export function LineItemPlanStatusIndicator({ result }: { result: LineItemPerformanceListRow }) {
  const entityIcon = <MediaPlanIcon />;
  const { media_platforms } = result;
  if (media_platforms.length === 0) {
    return <PlanStatusIndicator status="pending" entityIcon={entityIcon} />;
  }
  const {
    media_budget,
    num_media_buys,
    num_media_plans,
    delivery: { target_units },
    plan: { media_budget_allocated, units_allocated }
  } = result;

  const budgetAllocationPct =
    media_budget > 0 ? calcProportion(media_budget_allocated, media_budget) : undefined;
  const unitAllocationPct =
    target_units > 0 ? calcProportion(units_allocated, target_units) : undefined;
  const mediaPlanWithPlatformBuyPct =
    num_media_plans > 0 ? calcProportion(num_media_buys, num_media_plans) : undefined;

  if (
    budgetAllocationPct === undefined ||
    unitAllocationPct === undefined ||
    mediaPlanWithPlatformBuyPct === undefined
  ) {
    return <PlanStatusIndicator status="pending" entityIcon={entityIcon} />;
  }

  if (
    budgetAllocationPct === 100 &&
    unitAllocationPct === 100 &&
    mediaPlanWithPlatformBuyPct === 100
  ) {
    return <PlanStatusIndicator status="complete" entityIcon={entityIcon} />;
  }
  return <PlanStatusIndicator status="incomplete" entityIcon={entityIcon} />;
}
