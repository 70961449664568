import { z } from 'zod';
import { UTCDate } from '@date-fns/utc';
import { isEmpty } from 'lodash';

export const dateToUTC = z.union([z.string(), z.date()]).transform(date => new UTCDate(date));

export const coerceNullToZero = z.union([z.number(), z.null()]).transform(value => value ?? 0);

export const coerceStringToNumberOrNull = z
  .string()
  .transform(value => (isEmpty(value) || isNaN(Number(value)) ? null : parseFloat(value)));

export const dateToUTCOrNull = z
  .union([z.string(), z.date()])
  .transform(date => (date === '' ? null : new UTCDate(date)));
