import React, { useState } from 'react';
import { LineItemTableData } from './line-items-table';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy, faEye, faTrashCan } from '@fortawesome/pro-light-svg-icons';
import { useStore } from '../../store/store';
import { Link, useLocation } from 'react-router-dom';
import { LineItemDeleteModal } from './line-item-delete-modal';
import { CombinedLineItem } from '../../store/strategy-combiner';
import { v4 as uuid } from 'uuid';
import { useStrategyContext } from './strategy-context';
import { useCaptureLineItemCreate } from '../../utils/posthog/analytics';

export function LineItemActions({ data }: { data: LineItemTableData }) {
  const { pathname } = useLocation();
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const duplicateLineItems = useStore(state => state.duplicateLineItems);
  const { strategy } = useStrategyContext();
  const captureCreateEvent = useCaptureLineItemCreate();

  const disableDelete = data.media_plans.length > 0;

  function handleDuplicateLineItems(lineItems: CombinedLineItem[]) {
    if (lineItems.length > 0) {
      const idMapping = Object.fromEntries(lineItems.map(i => i.id).map(i => [i, uuid()]));
      duplicateLineItems(lineItems, idMapping);
      for (const lineItem of lineItems) {
        const duplicateId = idMapping[lineItem.id];
        captureCreateEvent(duplicateId, 'duplication', strategy.campaign);
      }
    }
  }

  return (
    <div className="relative">
      <div
        className={
          'shadow-l-lg fixed right-0 h-full border-l-[1px] border-gray-200 bg-white px-2 transition-[width]'
        }>
        <div className="flex h-full w-full items-center justify-center gap-1.5">
          <Link to={`${pathname}/../lineitems/${data.id}/overview`}>
            <LineItemAction
              className="bg-blue-600 hover:bg-blue-500"
              onClick={() => {}}
              icon={<FontAwesomeIcon icon={faEye} />}
            />
          </Link>
          <LineItemAction
            className="bg-blue-600 hover:bg-blue-500"
            onClick={() => handleDuplicateLineItems([data])}
            icon={<FontAwesomeIcon icon={faCopy} />}
          />
          <LineItemAction
            onClick={() => setDeleteModalOpen(true)}
            disabled={disableDelete}
            className="bg-red-600 hover:bg-red-500 disabled:bg-gray-300 disabled:text-gray-400"
            icon={<FontAwesomeIcon icon={faTrashCan} />}
          />
        </div>
      </div>
      <LineItemDeleteModal
        open={deleteModalOpen}
        setOpen={setDeleteModalOpen}
        lineItemsToDelete={[data]}
      />
    </div>
  );
}

type LineItemActionProps = {
  className: string;
  icon: React.ReactNode;
  onClick: () => void;
  disabled?: boolean;
};

function LineItemAction({ className, disabled = false, icon, onClick }: LineItemActionProps) {
  return (
    <button
      disabled={disabled}
      onClick={onClick}
      className={`flex items-center rounded p-1 text-white ${className}`}>
      {icon}
    </button>
  );
}
