import React from 'react';
import clsx from 'clsx';
import { MinusInCircle } from '../../../icons/minus-in-circle';

type Props = {
  children: React.ReactNode;
  className?: string;
  style?: Record<string, string>;
};

export function PropertyIndicatorContainer({ children, className, style }: Props) {
  return (
    <div
      className={clsx(
        'flex h-[34px] w-full flex-col justify-around gap-0.5 overflow-x-clip rounded px-2 pb-[5px] pt-[3px]',
        className
      )}
      style={style}>
      {children}
    </div>
  );
}

export function PropertyIndicatorLabel({ children, className }: Props) {
  return (
    <div className={clsx('flex items-center gap-1 text-sm font-bold', className)}>{children}</div>
  );
}

export function ProgressBarContainer({ children }: { children?: React.ReactNode }) {
  return (
    <div className="progress-bar-inner-shadow flex h-1 rounded-[3px] bg-gray-100">{children}</div>
  );
}

export function PendingPropertyIndicator({ label }: { label: React.ReactNode }) {
  return (
    <PropertyIndicatorContainer className="bg-gray-100">
      <PropertyIndicatorLabel className="text-xs font-normal text-gray-600">
        <MinusInCircle />
        {label}
      </PropertyIndicatorLabel>
      <ProgressBarContainer />
    </PropertyIndicatorContainer>
  );
}

export function CellContainer({ children }: { children: React.ReactNode }) {
  return <div className="flex h-[40px] w-full items-center">{children}</div>;
}

export function TooltipContainer({ children }: { children: React.ReactNode }) {
  return <div className="rounded bg-[#111928] px-2 py-1.5 text-white">{children}</div>;
}

export function DoneOMeterTooltip({ value }: { value: React.ReactNode }) {
  return <div className="rounded bg-[#111928] px-2 py-1.5 text-white">{value}</div>;
}

export function calcProportion(allocated: number, total: number) {
  return (allocated / total) * 100;
}
