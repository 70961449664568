import React from 'react';
import { useStrategyContext } from './strategy-context';
import { NewLineItemButton } from './new-line-item-button';
import { CombinedStrategy } from '../../store/strategy-combiner';
import { RateCardIcon } from './rate-card-icon';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalculator } from '@fortawesome/pro-light-svg-icons';
import { clsx } from 'clsx';
import { formatCurrencyNoDecimal } from '../../components/table-utils';

export function LineItemStatusPanelLeft() {
  const { strategy } = useStrategyContext();

  return (
    <div className="flex w-full flex-col px-3">
      <div className="flex h-[56px] border-b-[1px] border-gray-100 px-2 py-2">
        <NewLineItemButton strategyId={strategy.id} campaign={strategy.campaign} />
      </div>
      <div className="flex h-[36px] items-center px-2">
        <div className="mr-1">Rows:</div>
        <div className="text-xl font-bold">{lineItemCount(strategy).toLocaleString()}</div>
      </div>
    </div>
  );
}

export function LineItemStatusPanelRight() {
  const { strategy } = useStrategyContext();

  const budget = strategy.campaign.budget;
  const allocatedPrice = calcAllocatedPrice(strategy);
  const remaining = budget && allocatedPrice != null ? budget - allocatedPrice : null;
  return (
    <div className="flex flex-col px-3">
      <div className="flex h-[56px] items-center border-b-[1px] border-gray-100">
        <div className="flex-1" />
        <div className="mr-2 flex h-[30px]  items-center rounded border-[1px] border-blue-300 bg-[#3F9FFD0C] px-2">
          <RateCardIcon />
          <div className="ml-1 text-sm">Data filled from Rate Card</div>
        </div>
        <div className="mr-2 flex h-[30px] items-center rounded bg-[#F3F4F6] px-2">
          <FontAwesomeIcon icon={faCalculator} className="text-lg text-gray-600" />
          <div className="ml-2 text-sm">Auto-calculated data</div>
        </div>
      </div>
      <div className="flex h-[36px] items-center px-2">
        <div className="flex-1" />
        <MoneyInfo label={'Price'} value={budget} />
        <MoneyInfo label={'Allocated'} value={allocatedPrice} />
        <MoneyInfo
          label={'Remaining'}
          value={remaining}
          alert={remaining != null && remaining !== 0}
        />
      </div>
    </div>
  );
}

type MoneyInfoProps = {
  label: string;
  value: number | null | undefined;
  alert?: boolean;
};

function MoneyInfo({ label, value, alert = false }: MoneyInfoProps) {
  return (
    <div className={clsx('mr-3 flex items-center', alert ? 'border-b-2 border-red-400' : '')}>
      <div className="mr-1 text-sm">{label}:</div>
      <div className="text-lg font-bold">{value != null ? formatCurrencyNoDecimal(value) : ''}</div>
    </div>
  );
}

function calcAllocatedPrice(strategy: CombinedStrategy) {
  return strategy.line_items
    .filter(item => !item.is_deleted)
    .map(item => item.price)
    .filter(price => price != null)
    .reduce((a, b) => a + b, 0);
}

function lineItemCount(strategy: CombinedStrategy) {
  return strategy.line_items.filter(item => !item.is_deleted).length;
}
