import * as React from 'react';
import { useState } from 'react';
import './index.css';
import { ToastContainer } from 'react-toastify';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import { ProtectedRoute } from './auth/protected-route';
import { QueryCache, QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { httpLink } from '@trpc/client';
import { trpc } from './utils/trpc-client';
import { Login } from './features/auth/oauth-login';
import { CampaignStrategyPage } from './features/strategy/campaign-strategy-page';
import superjson from 'superjson';
import { ZohoCampaigns } from './features/zoho-campaigns/zoho-campaigns';
import { isStaging, posthogApiKey, posthogConfig } from './utils/env-utils';
import { ChannelRatesContextProvider } from './features/strategy/channel-rates-context';
import { Sidebar } from './components/sidebar';
import { AllPlatformBuys } from './features/media-buys/all-platform-buys';
import { StagingIndicator } from './components/staging-indicator';
import { showErrorToast } from './components/error-toast';
import { AllLineItems } from './features/line-items/all-line-items';
import { PreviousLocationProvider } from './utils/posthog/previous-location';
import { PostHogProvider } from 'posthog-js/react';
import { AllCampaigns } from './features/zoho-campaigns/all-campaigns';
import { useFlagEnabled } from './utils/feature-flags';
import { AllLineItemsV2 } from './features/line-items/all-line-items-v2';

export function App() {
  const [queryClient] = useState(
    () =>
      new QueryClient({
        defaultOptions: { queries: { retry: false }, mutations: { retry: false } },
        queryCache: new QueryCache({ onError: showErrorToast })
      })
  );
  const [trpcClient] = useState(() => {
    return trpc.createClient({
      links: [httpLink({ url: '/api', transformer: superjson })]
    });
  });

  return (
    <PostHogProvider apiKey={posthogApiKey} options={posthogConfig}>
      <trpc.Provider client={trpcClient} queryClient={queryClient}>
        <QueryClientProvider client={queryClient}>
          <BrowserRouter>
            <Routes>
              <Route path="/login" element={<Login />} />
              <Route
                path="/*"
                element={
                  <ProtectedRoute>
                    <InnerApp />
                  </ProtectedRoute>
                }
              />
            </Routes>
          </BrowserRouter>
        </QueryClientProvider>
      </trpc.Provider>
    </PostHogProvider>
  );
}

function InnerApp() {
  const isDoneOMeterLineItemsListingEnabled = useFlagEnabled('line-items-listing-dom');
  const { data: channels, isLoading: channelsIsLoading } = trpc.channels.useQuery();

  if (channelsIsLoading || !channels) {
    return <div>Loading....</div>;
  }

  return (
    <PreviousLocationProvider>
      <ChannelRatesContextProvider channels={channels}>
        <div className="fixed flex h-lvh w-full">
          {isStaging && <StagingIndicator />}
          <Sidebar />
          <Routes>
            <Route path="/campaigns" element={<ZohoCampaigns />} />
            <Route path="/campaignsv2" element={<AllCampaigns />} />
            <Route
              path="/line-items"
              element={isDoneOMeterLineItemsListingEnabled ? <AllLineItemsV2 /> : <AllLineItems />}
            />
            <Route path="/media-buys" element={<AllPlatformBuys />} />
            <Route path="/campaigns/:zohoCampaignId/*" element={<CampaignStrategyPage />} />
            <Route path="*" element={<Navigate to="/campaigns" />} />
          </Routes>
          <ToastContainer
            autoClose={false}
            position="bottom-left"
            hideProgressBar
            customProgressBar
            closeButton={false}
            toastStyle={{ padding: 0, width: 'auto', minHeight: 0 }}
          />
        </div>
      </ChannelRatesContextProvider>
    </PreviousLocationProvider>
  );
}
