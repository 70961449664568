import React from 'react';

export function CheckInCircle() {
  return (
    <svg height="14" width="14" fill="none" viewBox="0 0 14 14" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_6562_5933)">
        <path
          d="M7.00005 0.393799C3.34692 0.393799 0.393799 3.34692 0.393799 7.00005C0.393799 10.6532 3.34692 13.6282 7.00005 13.6282C10.6532 13.6282 13.6282 10.6532 13.6282 7.00005C13.6282 3.34692 10.6532 0.393799 7.00005 0.393799ZM7.00005 12.6438C3.8938 12.6438 1.37817 10.1063 1.37817 7.00005C1.37817 3.8938 3.8938 1.37817 7.00005 1.37817C10.1063 1.37817 12.6438 3.91567 12.6438 7.02192C12.6438 10.1063 10.1063 12.6438 7.00005 12.6438Z"
          fill="#4D7C0F"
        />
        <path
          d="M8.88127 4.96579L6.27815 7.50329L5.0969 6.34392C4.90002 6.14704 4.59377 6.16892 4.3969 6.34392C4.20002 6.54079 4.2219 6.84704 4.3969 7.04392L5.7969 8.40017C5.92815 8.53142 6.10315 8.59704 6.27815 8.59704C6.45315 8.59704 6.62815 8.53142 6.7594 8.40017L9.58127 5.68767C9.77815 5.49079 9.77815 5.18454 9.58127 4.98767C9.3844 4.79079 9.07815 4.79079 8.88127 4.96579Z"
          fill="#4D7C0F"
        />
      </g>
      <defs>
        <clipPath id="clip0_6562_5933">
          <rect height="14" width="14" fill="white" rx="6" />
        </clipPath>
      </defs>
    </svg>
  );
}
